import React from 'react'
import { makeStyles, Box, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  message: {
    textAlign: 'right',
  },
}))

const RequiredFieldMessage = () => {
  const classes = useStyles()
  return (
    <Box className={classes.message}>
      <Typography color="error" variant="caption">
        <sup>*</sup> Required field
      </Typography>
    </Box>
  )
}

export default RequiredFieldMessage
