/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { graphql, Link } from 'gatsby'
import { func, any, bool, number, oneOfType, objectOf, string } from 'prop-types'
import { checkProp } from '@helpers/check-prop'
import { savePageScroll } from '@helpers/product'
import { setShowSearchResults } from '@redux/modules/global'

class RTGLink extends PureComponent {
  render() {
    const {
      onSetShowSearchResults,
      data,
      children,
      category,
      action,
      label,
      value,
      style = {},
      disabled,
      className,
      target,
      trackingData,
      'aria-label': ariaLabel,
      'aria-selected': ariaSelected,
      'aria-labelledby': ariaLabelledBy,
      'aria-hidden': ariaHidden,
      'aria-describedby': ariaDescribedBy,
      'aria-checked': ariaChecked,
      'aria-expanded': ariaExpanded,
      'aria-controls': ariaControls,
      ariaLevel,
      noAriaControls,
      noAriaChecked,
      noAriaDescribedby,
      noAriaSelected,
      noAriaLabel,
      noAriaExpand,
      role,
      noRole,
      id,
      tabIndex,
      noTabIndex,
      onBlur,
      onFocus,
      onKey,
      onClick = () => {},
      renderSpan,
      onSpanMouseEnter,
    } = this.props
    const contentContainer = (text, spanWrap) => {
      if (spanWrap && typeof onSpanMouseEnter === 'function') {
        return <span onMouseEnter={onSpanMouseEnter} dangerouslySetInnerHTML={{ __html: text }} />
      }
      if (spanWrap) {
        return <span dangerouslySetInnerHTML={{ __html: text }} />
      }
      return text
    }
    const link = data
    let content = children
    if (React.Children.count(children) === 0) {
      content = link && link.displayText ? link.displayText : link.text
    }

    if (link) {
      const uri = link.url ? link.url : link.slug
      const gtm = {
        category: link.category ? link.category : category || null,
        action: link.action ? link.action : action || null,
        label: link.label ? link.label : label || uri,
        value: link.value ? link.value : value || null,
      }
      const linkStyle = link.textColor ? { color: link.textColor, ...style } : style
      if (link.url) {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href={disabled ? null : link.url}
            title={link.title ? link.title : link.altDesc}
            className={className}
            target={link.target ? link.target : checkProp(target)}
            key={link.id}
            style={linkStyle}
            rel={link.rel}
            tabIndex={checkProp(tabIndex, noTabIndex)}
            gtm-category={gtm.category}
            gtm-action={gtm.action}
            gtm-label={gtm.label}
            gtm-value={gtm.value}
            aria-label={checkProp(ariaLabel, noAriaLabel, link.altDesc)}
            aria-labelledby={checkProp(ariaLabelledBy)}
            aria-hidden={link.ariaHidden || ariaHidden}
            aria-level={ariaLevel}
            disabled={disabled}
            onClick={e => {
              onSetShowSearchResults(false)
              savePageScroll()
              onClick(e)
              return trackingData ? window.dataLayer.push(trackingData) : null
            }}
          >
            {content}
          </a>
        )
      }
      if (link.internalUrl || link.slug) {
        const url = link.internalUrl ? link.internalUrl.url : link.slug
        return (
          <a
            href={url.startsWith('/') ? url : `/${url}`}
            key={link.id}
            id={checkProp(id)}
            className={className}
            target={link.target ? link.target : checkProp(target)}
            style={linkStyle}
            title={link.title ? link.title : link.altDesc}
            rel={link.rel}
            gtm-category={gtm.category}
            gtm-action={gtm.action}
            gtm-label={gtm.label}
            gtm-value={gtm.value}
            role={checkProp(role, noRole)}
            tabIndex={link.tabIndex || checkProp(tabIndex, noTabIndex)}
            aria-hidden={link.ariaHidden || ariaHidden}
            aria-label={checkProp(ariaLabel, noAriaLabel, link.altDesc)}
            aria-labelledby={checkProp(ariaLabelledBy)}
            aria-describedby={checkProp(ariaDescribedBy, noAriaDescribedby)}
            aria-expanded={checkProp(ariaExpanded, noAriaExpand)}
            aria-selected={checkProp(ariaSelected, noAriaSelected)}
            aria-controls={checkProp(ariaControls, noAriaControls)}
            aria-checked={checkProp(ariaChecked, noAriaChecked)}
            aria-level={ariaLevel}
            onClick={e => {
              onSetShowSearchResults(false)
              savePageScroll()
              onClick(e)
              return trackingData ? window.dataLayer.push(trackingData) : null
            }}
            onKeyDownCapture={
              onKey
                ? e => {
                    onKey(e)
                  }
                : null
            }
            onFocus={
              onFocus
                ? e => {
                    onFocus(e)
                  }
                : null
            }
            onBlur={
              onBlur
                ? e => {
                    onBlur(e)
                  }
                : null
            }
          >
            {contentContainer(content, renderSpan)}
          </a>
        )
      }
    }
    return content
  }
}

RTGLink.propTypes = {
  onSetShowSearchResults: func,
  data: objectOf(any),
  children: any,
  category: string,
  action: string,
  label: string,
  value: oneOfType([string, number]),
  style: objectOf(any),
  disabled: bool,
  className: string,
  target: string,
  trackingData: objectOf(any),
  'aria-label': oneOfType([string, bool]),
  'aria-selected': oneOfType([string, bool]),
  'aria-labelledby': oneOfType([string, bool]),
  'aria-hidden': oneOfType([string, bool]),
  'aria-describedby': oneOfType([string, bool]),
  'aria-checked': oneOfType([string, bool]),
  'aria-expanded': oneOfType([string, bool]),
  'aria-controls': oneOfType([string, bool]),
  ariaLevel: oneOfType([string, bool]),
  noAriaControls: oneOfType([string, bool]),
  noAriaChecked: oneOfType([string, bool]),
  noAriaDescribedby: oneOfType([string, bool]),
  noAriaSelected: oneOfType([string, bool]),
  noAriaLabel: oneOfType([string, bool]),
  noAriaExpand: oneOfType([string, bool]),
  role: string,
  noRole: bool,
  id: oneOfType([string, number]),
  tabIndex: oneOfType([string, number]),
  noTabIndex: bool,
  onBlur: func,
  onFocus: func,
  onClick: func,
  onKey: func,
  renderSpan: bool,
  onSpanMouseEnter: func,
}

const mapStateToProps = state => ({ ...state.global })
const mapDispatchToProps = dispatch => ({
  onSetShowSearchResults: showSearchResults => dispatch(setShowSearchResults(showSearchResults)),
})
export default connect(mapStateToProps, mapDispatchToProps)(RTGLink)

export const link = graphql`
  fragment Link on ContentfulLink {
    id
    text
    displayText
    textColor
    internalUrl {
      url
    }
    slug
    url
    altDesc
    title
    type
    target
    rel
  }
`
