export const breakPoints = {
  small: '639px',
  medium: '768px',
  xlarge: '1366px',
  'medium-max': '767px',
  'medium-min': '768px',
  'large-max': '1024px',
  'large-min': '1025px',
  'x-large': '1670px',
}

export const fonts = {
  rootFontSize: 16,
  txtMini: '.5rem',
  txtXsmall: '.75rem',
  txtSmall: '.875rem',
  txtMedium: '1rem',
  txtXmedium: '1.125rem',
  txtMediumUp: '1.25rem',
  txtLarge: '1.5rem',
  txtLargeUp: '1.688rem',
  txtXlarge: '2rem',
}

export const colors = {
  black: '#000',
  primary: '#004f92',
  primaryInvert: '#ffffff',
  secondary: '#012a60',
  lightBlue: '#0053a0',
  red: '#eb141f',
  success: '#03AA67',
  disabled: '#D8D8D8',
  darkerGrey: '#333333',
  textHighlight: '#444444',
  darkGrey: '#717171',
  mediumGrey: '#7e7e7e',
  grey: '#707070',
  lightGrey: '#DEDEDE',
  green: '#008550',
  darkerGreen: '#008550',
  lighterGrey: '#F7F7F7',
  white: '#ffffff',
  offWhite: '#f2f2f2',
  lightGray: '#F6F6F6',
}

// Based on Style Guide [v1.5.5]
// => https://www.sketch.com/s/0dafc53e-8366-4245-a793-44e19541e407/a/VrWLWZA
export const theme = {
  primary: '#0053A0',
  secondary: '#003566',
  tertiary: '#E31C3D',
  accent: '#FFB836',
  grayDark: '#333333',
  grayMedium: '#7E7E7E',
  gray: '#DEDEDE',
  grayLight: '#F6F6F6',
  white: '#FFFFFF',
  heading: {
    h1: '40px',
    h2: '36px',
    h3: '32px',
    h4: '28px',
    h5: '24px',
    h6: '20px',
  },
  weight: {
    light: 100,
    regular: 400,
    semiBold: 600,
    bold: 700,
  },
  breakpoint: {
    mobile: '320px',
    tablet: '758px',
    desktop: '1024px',
    large: '1670px',
  },
}
