import React from 'react'
import { navigate } from 'gatsby'

import { styled, Box, Typography, Button } from '@mui/material'
import PropTypes from 'prop-types'
import PersonIcon from '@mui/icons-material/PersonOutline'
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'

import { useUserQuery } from '@hooks/auth'
import { useModalsContext } from '@context/modalContext'
import { useFirebaseContext } from '@context/firebaseContext'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'

const Wrapper = styled(Box)`
  background-color: #f2f2f2;
  padding: ${p => p.theme.spacing(2)};

  p,
  a {
    font-size: 0.9rem;
  }
`

const Buttons = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-top: ${p => p.theme.spacing(2)};
`

const MyAccountMenu = ({ onDrawerClose }) => {
  const { data: userQuery } = useUserQuery()
  const { user } = useFirebaseContext()
  const { dispatch, actions } = useModalsContext()

  const onAccountClick = () => {
    if (user) {
      onDrawerClose()
      navigate('/account/profile')
    } else {
      onDrawerClose()
      dispatch({ type: actions.login })
    }
  }

  return (
    <Wrapper>
      {user ? (
        <Typography variant="body2">Welcome, {userQuery?.contact?.firstName}</Typography>
      ) : (
        <Typography variant="body2">
          Welcome
          <Button
            onClick={onAccountClick}
            size="medium"
            sx={{ textDecoration: 'underline', textTransform: 'capitalize', color: 'text.primary', fontSize: '0.9rem' }}
          >
            Login / Create Account
          </Button>
        </Typography>
      )}
      <Buttons>
        <Button onClick={onAccountClick} size="small" startIcon={<PersonIcon />}>
          Account
        </Button>
        <Button
          onClick={onDrawerClose}
          size="small"
          startIcon={<LocalOfferIcon />}
          component={ButtonLink}
          data={{
            InternalUrl: '/order/status',
            Title: 'Order status',
          }}
          trackingData={{
            category: 'header accounts',
            action: 'orders page',
            label: 'go to order status page',
          }}
        >
          Orders
        </Button>
        <Button
          onClick={onDrawerClose}
          size="small"
          startIcon={<FavoriteBorderOutlinedIcon />}
          component={ButtonLink}
          data={{
            InternalUrl: '/favorites',
            Title: 'Favorites',
          }}
          trackingData={{
            category: 'header favorites',
            action: 'favorites click',
            label: 'go to favorites page',
          }}
        >
          Favorites
        </Button>
      </Buttons>
    </Wrapper>
  )
}

MyAccountMenu.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
}

export default MyAccountMenu
