import React from 'react'

import PropTypes from 'prop-types'
import { Grid, Box, styled, ClickAwayListener } from '@mui/material'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import LeftSideBanners from './LeftSideBanners'
import NavigationFooter from './Footer'

const GridWrapper = styled(Grid)`
  .link {
    color: #333;
    text-decoration: none;
    display: block;
    padding-bottom: 6px;
    &:hover,
    &:focus {
      color: ${p => p.theme.palette.primary.main};
      text-decoration: underline;
      outline: none !important;
    }
  }

  .subcategory {
    font-size: 0.9rem;
  }

  .heading {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`

const RightSide = styled(Grid)`
  max-height: 560px;
  overflow-y: auto;
  overflow-x: hidden;
`

const LinkListWrapper = styled(Box)`
  min-width: 186px;
  padding-bottom: ${p => p.theme.spacing(2)};
  padding-right: ${p => p.theme.spacing(2)};
`

const InnerWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const Megamenu = ({ data, onClickAway, onClose }) => {
  const navLinks = data?.NavLinks
  const primaryLink = data?.PrimaryLink?.Link
  const bannerGroup = data?.LeftSideCategoryBanners?.NavigationBannerGroup
  const navFooter = data?.NavigationFooter

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <GridWrapper container spacing={2}>
        <LeftSideBanners bannerGroup={bannerGroup} onClose={onClose} />
        <RightSide container item sm={bannerGroup ? 6 : 12}>
          <InnerWrapper sx={{ maxHeight: bannerGroup ? '860px' : '460px' }}>
            {navLinks?.map?.(({ LinkList }, index) => (
              <LinkListWrapper item key={LinkList?.id}>
                {index === 0 && (
                  <Box key={primaryLink?.id} sx={{ pb: 1 }}>
                    <StrapiLink
                      className="link heading"
                      data-textid={primaryLink?.id}
                      data={primaryLink}
                      onClick={onClose}
                      trackingData={{
                        category: 'navigation',
                        action: 'link click',
                        label: primaryLink?.Title || primaryLink?.DisplayText,
                      }}
                    >
                      Shop All
                    </StrapiLink>
                  </Box>
                )}

                <Box key={LinkList?.id} sx={{ pb: 1 }}>
                  <StrapiLink
                    className="link heading"
                    data-textid={LinkList?.HeadingLink?.Link?.id}
                    data={LinkList?.HeadingLink}
                    onClick={onClose}
                    trackingData={{
                      category: 'navigation',
                      action: 'link click',
                      label: LinkList?.HeadingLink?.Link?.Title || LinkList?.HeadingLink?.Link?.DisplayText,
                    }}
                  />
                  {LinkList?.Links?.map?.(({ Link }, subI) => (
                    <StrapiLink
                      key={Link?.id}
                      data-textid={Link?.id}
                      className="link subcategory"
                      data={Link}
                      onClick={onClose}
                      trackingData={{
                        category: 'navigation',
                        action: 'link click',
                        label: Link?.Title || Link?.DisplayText,
                      }}
                    />
                  ))}
                </Box>
              </LinkListWrapper>
            ))}
          </InnerWrapper>
        </RightSide>
        <NavigationFooter onClose={onClose} data={navFooter} />
      </GridWrapper>
    </ClickAwayListener>
  )
}

Megamenu.propTypes = {
  data: PropTypes.object.isRequired,
  onClickAway: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Megamenu
