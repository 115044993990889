import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { uniqueId } from 'lodash'

/**
 * @param {string} url
 */
export const isSynchronyApplyLink = url => url?.includes('https://etail.mysynchrony.com/eapply/eapply.action')

const StyledLink = styled.a`
  cursor: pointer;
`
const ApplyButton = ({ title, url, category, action, label, value, children, asButton, trackingData, ...rest }) => {
  const id = useMemo(() => `syfClientID${uniqueId()}`, [])

  /**
   * @param {Event} e
   */
  const onApply = e => {
    e.preventDefault()
    window.syfClient({
      dApplyOpener: [id],
      dApplyUrl: url,
      addlistener: false,
    })
  }
  const sharedProps = {
    id,
    role: 'button',
    'gtm-category': category,
    'gtm-action': action,
    'gtm-value': value,
    'gtm-label': label,
    onClick: e => {
      onApply(e)
      window.dataLayer.push(trackingData)
    },
  }
  if (asButton) {
    return (
      <StyledLink rel="nofollow" {...rest} {...sharedProps}>
        {children}
      </StyledLink>
    )
  }

  return (
    <StyledLink target="_blank" rel="nofollow" type="button" {...rest} {...sharedProps}>
      {children}
      <span className="hide508">(opens in new window)</span>
    </StyledLink>
  )
}

ApplyButton.propTypes = {
  trackingData: PropTypes.object,
  asButton: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  category: PropTypes.string,
  action: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
}

export default ApplyButton
