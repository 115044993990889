import React, { useState } from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Tooltip,
  CardMedia,
  Typography,
  Skeleton,
  Stack,
  Button,
  Avatar,
  Tabs,
  Tab,
  Chip,
  styled,
  useTheme,
} from '@mui/material'

import StrapiLink from '@templates/strapi-cms/content-types/Link'
import PropTypes from 'prop-types'
import ProductPrice from '../../atoms/ProductPrice/ProductPrice'
import FavoriteButton from '../../atoms/Button/Favorite'

// TODO: Pending the following:
// - Accessibility
// - Finish variants
// - Size variants
// - Various sale flags
// - "+more" logic
// - Polish financing
// - Bullets between sale flags
// - Create storybook component for financeAmount
// - Fix skeletons
// - Error messaging

const StyledCard = styled(Card)`
  .room_image {
    aspect-ratio: 2/1;
  }

  .item_image {
    aspect-ratio: 2/1;
    object-fit: contain !important;
    background-color: ${p => p.theme.palette.secondary.light};
    padding: 0.625rem;
    margin: 0 auto;
  }

  .active {
    border: 2px solid ${p => p.theme.palette.primary.main} !important;
  }
`

export default function ProductTile({
  primary_image,
  type,
  title,
  sku,
  route,
  default_price,
  price,
  sale,
  availability,
  financeAmount,
  financeNumMonths,
  color,
  finish,
  size,
  isChecked,
  room_image,
  isLoading,
  isError,
  updateFavorite,
  activeColorVariant,
  region,
  updateVariant,
  inViewRef,
  showVariations = false,
  strikeThroughPrice = 0,
  strikeThrough = false,
}) {
  const [checked, setChecked] = useState(isChecked)

  const theme = useTheme()

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onFavoriteButtonClick = () => {
    updateFavorite({ remove: checked })
    setChecked(!checked)
  }

  if (isLoading || !sku)
    return (
      <Stack direction="column" ref={inViewRef}>
        <Skeleton height={375} width="100%" />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </Stack>
    )

  return (
    <StyledCard
      square
      variant="outlined"
      sx={{
        overflow: 'visible',
        width: '100%',
        position: 'relative',
        height: '100%',
      }}
      ref={inViewRef}
    >
      {isError && <div>Error {JSON.stringify(isError)}</div>}
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardActionArea disableRipple>
          <StrapiLink
            className="product-image-link"
            data={{
              InternalUrl: route,
              id: `product-tile-link:${sku}`,
              Title: title,
            }}
            trackingData={{
              category: 'product tile',
              action: 'click',
              event: 'ee_click',
              label: sku,
            }}
            aria-hidden="true"
            tabIndex="-1"
          >
            <CardMedia
              width="535px"
              component="img"
              aria-hidden="true"
              role="presentation"
              alt={title}
              title={`${title}`}
              image={type === 'room' && room_image ? room_image : primary_image}
              className={type === 'room' ? 'room_image' : 'item_image'}
            />
          </StrapiLink>
        </CardActionArea>
        <CardContent
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div>
            {/* Chips */}
            <Stack direction="row" spacing={1} mb={1}>
              {sale && (
                <Chip
                  label="SALE"
                  color="error"
                  size="small"
                  sx={{ borderRadius: '2px', fontSize: '1rem', lineHeight: '24px', fontWeight: 600 }}
                />
              )}
              {availability ? (
                <Chip
                  label="In Stock"
                  color="success"
                  size="small"
                  variant="outlined"
                  sx={{
                    border: 'none',
                    fontSize: '1rem',
                    lineHeight: '24px',
                    fontWeight: 600,
                    '.MuiChip-label': {
                      padding: 0,
                    },
                  }}
                />
              ) : (
                <Chip
                  label="Unavailable"
                  color="secondary"
                  size="small"
                  sx={{ borderRadius: '2px', fontSize: '1rem', lineHeight: '24px' }}
                />
              )}
            </Stack>
            {/* Product Title */}
            {/* <Tooltip title={title}> */}
            <Typography gutterBottom variant="body1" component="h2" lineHeight="24px">
              {title}
            </Typography>
            {/* </Tooltip> */}

            <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="baseline">
              {/* Product Price */}
              <ProductPrice
                price={price}
                sale={sale}
                default_price={default_price}
                strikeThrough={strikeThrough}
                strikeThroughPrice={strikeThroughPrice}
                variant="subtitle1"
              />
              {/* Product Financing Message */}
              {financeAmount >= 5 && (
                <Typography variant="caption" color={theme.palette.secondary.dark} fontStyle="italic">
                  {`$${financeAmount}/mo. for ${financeNumMonths} months*`}
                </Typography>
              )}
            </Stack>
          </div>
          <div>
            {/* Variants */}
            {activeColorVariant && showVariations && (
              <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                <Tabs
                  value={value}
                  scrollButtons={false}
                  visibleScrollbar
                  onChange={e => handleChange(e)}
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                  aria-label="scrollable Variants example"
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    {activeColorVariant?.slice(0, 5).map(
                      variant =>
                        variant.catalog_availability[region] && (
                          <Tab
                            key={variant.sku}
                            className={variant.sku === sku ? 'active' : ''}
                            // label={variant.variation_value}
                            icon={
                              <Tooltip title={variant.variation_value}>
                                <Avatar
                                  // className={variant.variation_value === selectedVariant && classes.active}
                                  alt={variant.variation_value}
                                  src={variant.image}
                                  onClick={() => updateVariant(variant.sku)}
                                  sx={{
                                    outline: `4px solid ${theme.palette.common.white}`,
                                    '.MuiAvatar-img': {
                                      width: '200%',
                                      height: '200%',
                                      maxWidth: '200%',
                                    },
                                  }}
                                />
                              </Tooltip>
                            }
                            iconPosition="start"
                            disableRipple
                            sx={{
                              // TODO: Create custom component from this
                              // outline: `2px solid ${theme.palette.primary.main}`,
                              borderRadius: '50%',
                              opacity: 1,
                              minWidth: 48,
                              minHeight: 48,
                              height: variant.sku === sku && 48,
                              width: variant.sku === sku && 48,
                              padding: 0,
                            }}
                          />
                        ),
                    )}
                    {/* TODO: Make this dynamic */}
                    {color?.length > 5 && <Typography variant="caption">+ MORE</Typography>}
                    {size?.length > 5 && <Typography variant="caption">+ MORE</Typography>}
                    {finish?.length > 5 && <Typography variant="caption">+ MORE</Typography>}
                  </Stack>
                </Tabs>
              </Box>
            )}
            <StrapiLink
              className="product-image-link"
              data={{
                InternalUrl: route,
                id: `product-tile-link:${sku}`,
                Title: title,
              }}
              trackingData={{
                category: 'product tile',
                action: 'click',
                event: 'ee_click',
                label: sku,
              }}
              aria-hidden="true"
              tabIndex="-1"
            >
              <Button variant="contained" color="primary" size="large" fullWidth sx={{ mt: 1 }}>
                View Details
              </Button>
            </StrapiLink>
          </div>
        </CardContent>
      </Box>
      <CardActions
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <FavoriteButton checked={checked} handleClick={onFavoriteButtonClick} />
      </CardActions>
    </StyledCard>
  )
}

ProductTile.propTypes = {
  primary_image: PropTypes.string,
  room_image: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  sku: PropTypes.string,
  route: PropTypes.string,
  default_price: PropTypes.number,
  price: PropTypes.number,
  strikeThroughPrice: PropTypes.number,
  strikeThrough: PropTypes.bool,
  sale: PropTypes.bool,
  availability: PropTypes.bool,
  financeAmount: PropTypes.number,
  financeNumMonths: PropTypes.number,
  color: PropTypes.array,
  finish: PropTypes.array,
  size: PropTypes.array,
  activeColorVariant: PropTypes.string,
  region: PropTypes.string,
  updateVariant: PropTypes.func,
  isChecked: PropTypes.bool,
  inViewRef: PropTypes.func.isRequired /** Required for InView logic of useProductQuery */,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  updateFavorite: PropTypes.func,
  showVariations: PropTypes.bool,
}
