/**
 *
 * Documentation:
 *
 * - [Alert](https://mui.com/material-ui/react-alert/)
 *
 * API:
 *
 * - [Alert API](https://mui.com/material-ui/api/alert/)
 * - inherits [Paper API](https://mui.com/material-ui/api/paper/)
 */

const MuiAlert = {
  defaultProps: {
    variant: 'filled',
    severity: 'success',
  },
  variants: [
    {
      props: { variant: 'snackbar' },
      style: {
        color: '#fff',
        backgroundColor: 'rgb(50,50,50)',
      },
    },
  ],
}

export default MuiAlert
