import { useCallback, useState } from 'react'

/**
 * @typedef {Object} PasswordVisibility
 * @property {boolean} showPassword
 * @property {boolean} showConfirmation
 * @property {(type: string) =>  () => void} onVisibilityToggle
 */

const handleMouseDownPassword = event => {
  event.preventDefault()
}

/**
 * Password Visibility hook
 * @returns {PasswordVisibility}
 */
const usePasswordVisibility = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const onVisibilityToggle = useCallback(
    type => () => {
      if (type === 'password') {
        setShowPassword(!showPassword)
      } else {
        setShowConfirmation(!showConfirmation)
      }
    },
    [showPassword, showConfirmation],
  )

  return { showPassword, showConfirmation, onVisibilityToggle, handleMouseDownPassword }
}

export default usePasswordVisibility
