const palette = {
  mode: 'light',
  common: {
    black: '#000',
    white: '#fff',
  },
  primary: {
    light: '#fff',
    main: '#0053a0',
    dark: '#003566',
  },
  secondary: {
    light: '#f6f6f6',
    main: '#dedede',
    dark: '#333',
  },
  warning: {
    light: '#fff4e5',
    main: '#ffcb00',
    dark: '#995c00',
  },
  success: {
    light: '#edf7ed',
    main: '#00854a',
    dark: '#275929',
  },
  info: {
    light: '#e5f6fd',
    main: '#03adfc',
    dark: '#026897',
  },
  error: {
    light: '#fdeded',
    main: '#e11f21',
    dark: '#a3120f',
  },
}

export default palette
