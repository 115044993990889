// dependencies
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  styled,
  Badge,
  IconButton,
  AppBar as MuiAppBar,
  Box,
  Toolbar as MuiToolbar,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import LocationIcon from '@mui/icons-material/LocationOnOutlined'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import ActiveFavoriteIcon from '@mui/icons-material/Favorite'
// assets
import LogoDesktopSvg from '@shared/svgs/logo'
// constants
import useStore from '@store'
import { useFirebaseContext } from '@context/firebaseContext'
// components
import StrapiLink, { ButtonLink } from '@templates/strapi-cms/content-types/Link'
import SearchForm from '@shared/search-form'
import { getRegionZone } from '@helpers/geo-location'
import { NavButton } from '../Styled'
import DesktopNavigation from '../Navigation'
import MiniCartDropdown from './MiniCartDropdown'
import MyAccountDropDown from './MyAccountDropDown'
import ShopAndDeliverTo from './ShopAndDeliverTo'

const regionInPR = getRegionZone().region === 'PR'

const viewCartTestIds = {
  viewCartLink: 'cartButton',
}

const AccountContent = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
  a:focus {
    outline: none !important;
  }
`

const LogoLink = styled(StrapiLink)`
  display: block;
  width: 330px;
  padding-right: ${p => p.theme.spacing(3)};
`

const Toolbar = styled(MuiToolbar)`
  max-width: 104rem;
  width: 100%;
  min-height: unset !important;
  justify-content: space-between;

  &.main-header {
    height: 70px;
  }
  &.meganav {
    max-width: unset !important;
    padding: 0;
  }
`

const StoreLocator = styled(Box)`
  padding-right: ${p => p.theme.spacing(2)};
  border-right: solid 1px gray;
`

const AppBar = styled(MuiAppBar)`
  align-items: center;
`

const isFavoriteActive = () => {
  if (typeof window !== 'undefined') {
    return window?.location?.pathname?.includes?.('/favorites')
  }
  return false
}

const iconSx = { fontSize: '2rem !important', color: 'primary.dark' }

export const DesktopAppBar = ({ isCheckout, onSetBackdrop }) => {
  const initializeFavorites = useStore(store => store.favorites.initialize)

  const { user } = useFirebaseContext()
  const favorites = useStore(store => store.favorites.list)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    initializeFavorites(user)
  }, [initializeFavorites, user])

  // Removes Desktop Hedear on browser runtime (when is mobile) but renders it at build time
  if (typeof window !== 'undefined' && !isDesktop) {
    return null
  }

  return (
    <AppBar position="fixed" className="dektop-only">
      <Toolbar className="main-header">
        <LogoLink
          data={{
            InternalUrl: '/',
            title: 'Rooms To Go Home',
          }}
          trackingData={{
            category: 'header',
            action: 'logo click',
            label: 'logo',
          }}
        >
          <LogoDesktopSvg uniqueNameForId="dektop-header" />
        </LogoLink>
        {!isCheckout && (
          <a href="#primary-nav-desktop" className="skip">
            Skip to primary navigation
          </a>
        )}
        {isCheckout && (
          <Button
            component={ButtonLink}
            data-testid={viewCartTestIds.viewCartLink}
            data={{
              InternalUrl: '/cart',
              title: 'Return to cart',
            }}
            trackingData={{ category: 'header', action: 'return to cart click', label: 'return to cart' }}
          >
            Return to Cart
          </Button>
        )}
        {!isCheckout && (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <SearchForm id="search-desktop" addClasses={{ active: true }} />
            </Box>
            <AccountContent>
              {/* LOCATION */}
              <StoreLocator>
                <NavButton
                  component={ButtonLink}
                  data={{
                    InternalUrl: '/stores',
                    title: 'Stores',
                  }}
                  trackingData={{
                    category: 'header',
                    action: 'stores interaction',
                    label: 'go to stores',
                  }}
                  startIcon={<LocationIcon sx={iconSx} />}
                >
                  <span className="text-wrapper">
                    <span className="btn-text underline">Stores</span>
                  </span>
                </NavButton>
              </StoreLocator>
              {/* ACCOUNT POPUP */}
              {!regionInPR && <MyAccountDropDown />}
              {/* DELIVERY TO */}
              <ShopAndDeliverTo />
              {/* FAVORITES */}
              {!regionInPR && (
                <IconButton
                  component={ButtonLink}
                  data={{
                    InternalUrl: '/favorites',
                    Title: 'Favorites',
                  }}
                  trackingData={{
                    category: 'header favorites',
                    action: 'favorites click',
                    label: 'go to favorites page',
                  }}
                  sx={{ ml: 2 }}
                >
                  <Badge badgeContent={favorites.size} color="primary">
                    {isFavoriteActive() ? (
                      <ActiveFavoriteIcon sx={iconSx} />
                    ) : (
                      <FavoriteBorderOutlinedIcon sx={iconSx} />
                    )}
                  </Badge>
                </IconButton>
              )}
              {/* CART POPUP */}
              <MiniCartDropdown />
            </AccountContent>
          </>
        )}
      </Toolbar>
      {!isCheckout && (
        <Toolbar className="meganav">
          <DesktopNavigation onSetBackdrop={onSetBackdrop} />{' '}
        </Toolbar>
      )}
    </AppBar>
  )
}

DesktopAppBar.propTypes = {
  onSetBackdrop: PropTypes.func.isRequired,
  isCheckout: PropTypes.bool,
}

export default DesktopAppBar
