import React from 'react'

import PropTypes from 'prop-types'
import { List as MuiList } from '@mui/material'

import useStaticNavigationQuery from '@hooks/useStaticNavigationQuery'
import ListItem from './ListItem'

const List = ({ onDrawerClose }) => {
  const navigation = useStaticNavigationQuery()
  return (
    <MuiList sx={{ width: '100%' }} component="nav" disablePadding>
      {navigation.map(({ HeaderNavLinks }) => {
        const contentID = `mobile-${HeaderNavLinks?.id}`
        return <ListItem key={contentID} onDrawerClose={onDrawerClose} data={HeaderNavLinks} />
      })}
    </MuiList>
  )
}

List.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
}

export default List
