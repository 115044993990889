import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateEmail as updateFirebaseEmail,
} from 'firebase/auth'
import { HttpError, request } from '@helpers/general'

export const getBody = (values = {}) => {
  const { firstName, lastName, zipcode: zip, email, phone, address1, address2, city, state } = values
  return {
    contact: {
      firstName, // required
      lastName, // required
      email, // required
      phone: phone || '',
    },
    address: {
      zip, // required
      address1: address1 || '',
      address2: address2 || '',
      city: city || '',
      state: state || '',
    },
  }
}

const service = ({ path = '/accounts', body = null, method = 'GET', token = null }) => {
  const baseUrl = process.env.GATSBY_USER_ACCOUNTS_SERVICE
  if (!token) {
    return Promise.reject(new HttpError('Account Service Error: No token was provided'))
  }
  return request(path, body, method, token, 'Account Service Error', baseUrl, 'X-Token')
}

export const api = {
  get: token => service({ token }),
  post: (token, body = null) => service({ body, method: 'POST', token }),
  put: (token, body = null) => service({ body, method: 'PUT', token }),
  delete: (token, body = null) => service({ body, method: 'DELETE', token }),
}

export const updateAccountInfo = auth => async ({ values = {}, oldEmail = null }) => {
  if (!auth.currentUser) {
    throw new HttpError('Account Service: No user is logged in')
  }

  if (values.email && values.currentPassword && oldEmail) {
    const credentials = await signInWithEmailAndPassword(auth, oldEmail, values.currentPassword)
    await updateFirebaseEmail(credentials.user, values.email)
    return api.put(credentials.user.accessToken, getBody(values))
  }

  return api.put(auth.currentUser.accessToken, getBody(values))
}

export const userQuery = ({ queryKey }) => {
  const [key, token] = queryKey
  return api.get(token)
}

export const logIn = auth => async ({ values = {} }) => {
  if (!auth) {
    throw new HttpError('Account Service: firebase auth is missing')
  }
  const { email, password } = values
  const user = await signInWithEmailAndPassword(auth, email, password)
  const result = await api.get(user.user.accessToken)
  return result
}

export const createAccount = auth => async ({ values = {} }) => {
  if (!auth) {
    throw new HttpError('Account Service: firebase auth is missing')
  }
  const { email, password } = values
  const user = await createUserWithEmailAndPassword(auth, email, password)
  const result = await api.post(user.user.accessToken, getBody(values))
  return { ...result, emailList: values?.emailList }
}
