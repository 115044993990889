// eslint-disable-next-line import/prefer-default-export
export const productUrl = route => `/furniture/product${route}`

const productListUrlMatcher = `/furniture/`
const productDetailsUrlMatcher = `/furniture/product/`

export const isRoutePLP = route =>
  !!route && route.startsWith(productListUrlMatcher) && !route.startsWith(productDetailsUrlMatcher)

export const isRoutePDP = route => !!route && route.includes(productDetailsUrlMatcher)
