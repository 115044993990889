import { MODAL_ID as CreateAccountID, CreateNewAccount } from '@components/accounts/CreateNewAccount'
import { MODAL_ID as LoginID, Login } from '@components/accounts/Login'
import { MODAL_ID as LogoutID, Logout } from '@components/accounts/Logout'
import {
  MODAL_ID as ForgotPasswordID,
  MODAL_RESET_PWD_ID as ResetPasswordID,
  ForgotPassword,
  ResetPassword,
  EmailSent,
  EmailSentID,
} from '@components/accounts/ForgotPassword'

/**
 * @typedef {Object} ModalActions
 * @property {string} createAccount triggers Create Account modal
 * @property {string} login triggers Login modal
 * @property {string} logout triggers Logout modal
 * @property {string} forgotPassword triggers forgot password modal
 * @property {string} resetPassword triggers reset password modal
 * @property {string} emailSent triggers forgot email sent modal
 * @property {string} reset sets the state to a empty object
 * @property {string} changeTitle
 * @property {string} changeDescription
 * @property {string} open
 * @property {string} close
 */

/**
 * Available actions in the accounts context
 * @type {ModalActions}
 * */
export const actions = {
  createAccount: CreateAccountID,
  login: LoginID,
  logout: LogoutID,
  forgotPassword: ForgotPasswordID,
  resetPassword: ResetPasswordID,
  emailSent: EmailSentID,
  reset: 'reset',
  changeTitle: 'changeTitle',
  changeDescription: 'changeDescription',
  open: 'open',
  close: 'close',
}

/**
 * @typedef {Object} ModalState
 * @property {boolean} isOpen
 * @property {"xs" | "sm" | "md" | "lg" | "xl" | false} maxWidth
 * @property {string} id
 * @property {Element} component
 * @property {string} title
 * @property {string} titleID
 * @property {string} description
 * @property {string} descriptionID
 */

/**
 * Initial state for every modal
 * @type {Record<string, ModalActions>}
 * */
const initialModalState = {
  [CreateAccountID]: {
    isOpen: true,
    maxWidth: 'md',
    id: CreateAccountID,
    component: CreateNewAccount,
    title: 'Create Account',
    titleID: 'create_account_modal_title',
    description:
      'Create an account to save your favorites, track your orders and delivery or pay an open balance. It’s easy!',
    descriptionID: 'create_account_modal_description',
  },
  [LoginID]: {
    isOpen: true,
    maxWidth: 'sm',
    id: LoginID,
    component: Login,
    title: 'Log In',
    titleID: 'login_modal_title',
    description: 'Please provide the requested information below.',
    descriptionID: 'login_modal_description',
    sources: {
      favorites: {
        title: 'Log in to Add Your Favorites',
      },
    },
  },
  [ForgotPasswordID]: {
    isOpen: true,
    maxWidth: 'sm',
    id: ForgotPasswordID,
    component: ForgotPassword,
    title: 'Forgot Password?',
    titleID: 'forgot_password_modal_title',
    description: 'Enter your registered email below to receive password recovery instructions.',
    descriptionID: 'forgot_password_modal_description',
  },
  [EmailSentID]: {
    isOpen: true,
    maxWidth: 'sm',
    id: EmailSentID,
    component: EmailSent,
    title: 'Check your email!',
    titleID: 'forgot_password_modal_title',
    description: 'We have sent password recovery instructions to your email.',
    descriptionID: 'forgot_password_modal_description',
  },
  [ResetPasswordID]: {
    isOpen: true,
    maxWidth: 'sm',
    id: ResetPasswordID,
    component: ResetPassword,
    title: 'Create New Password',
    titleID: 'reset_password_modal_title',
    description: 'Enter a new password for your account.',
    descriptionID: 'reset_password_modal_description',
  },
  [LogoutID]: {
    isOpen: true,
    maxWidth: 'sm',
    id: LogoutID,
    component: Logout,
    title: 'Log Out',
    titleID: 'logout_options_modal_title',
    description: 'Are you sure?',
    descriptionID: 'logout_options_modal_description',
  },
}

/**
 * Modals reducer
 * @param {ModalState} state
 * @param {object} action
 * @returns {ModalState}
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case actions.forgotPassword:
    case actions.resetPassword:
    case actions.createAccount:
    case actions.emailSent:
    case actions.login:
      return { ...initialModalState[action.type], ...initialModalState[action.type]?.sources?.[action.source] }
    case actions.logout:
      return { ...initialModalState[action.type] }

    case actions.changeTitle:
      return {
        ...state,
        title: action.title,
      }

    case actions.changeDescription:
      return {
        ...state,
        description: action.description,
      }

    case actions.open:
      return {
        ...state,
        isOpen: true,
      }

    case actions.close:
      return {
        ...state,
        isOpen: false,
      }

    case actions.reset:
    default:
      return {}
  }
}
