import React, { useState } from 'react'

import { Box, Badge, Paper, IconButton, styled, Fade } from '@mui/material'
import { useSelector } from 'react-redux'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'
import { getLineItemQuantity } from '@helpers/cart'
import MiniCart from '@components/header/MiniCart'

const BUTTON_ID = 'header-mini-cart-btn'
const MENU_ID = 'header-mini-cart-menu'

const PopOver = styled(Paper)`
  position: absolute;
  top: 40px;
  padding: ${p => p.theme.spacing(2)};
  right: 0;
  width: 420px;
  max-height: calc(100vh - 250px);
  background-color: #fff;
  border-radius: 0;
  border: solid 1px grey;
  overflow-y: auto;
  z-index: ${p => p.theme.zIndex.appBar};
`

const MiniCartDropdown = () => {
  const [open, setOpen] = useState(false)

  const cartQuantity = useSelector(state => getLineItemQuantity(state.order).cart)

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const onKeyDown = e => {
    if (e.key === 'Escape' || (e.shiftKey && e.key === 'Tab')) {
      onClose()
    }
  }

  const buttonAriaProps = {
    'aria-haspopup': 'true',
    'aria-label': `View Cart: ${cartQuantity} items`,
    ...(open
      ? {
          'aria-controls': MENU_ID,
          'aria-expanded': 'true',
        }
      : {}),
  }

  return (
    <Box onMouseEnter={onOpen} onMouseLeave={onClose} sx={{ position: 'relative' }} onKeyDown={onKeyDown}>
      <IconButton
        id={BUTTON_ID}
        component={ButtonLink}
        color="primary"
        data-testid="cartButton"
        data={{
          InternalUrl: '/cart',
          title: 'View Cart',
        }}
        sx={{ ml: 2 }}
        trackingData={{ category: 'header', action: 'cart interaction', label: 'view full cart' }}
        onFocus={onOpen}
        onKeyDown={onKeyDown}
        {...buttonAriaProps}
      >
        <Badge color="primary" badgeContent={cartQuantity}>
          <ShoppingCartIcon sx={{ fontSize: '2rem !important' }} />
        </Badge>
      </IconButton>
      <Fade in={open} unmountOnExit>
        <PopOver
          open={open}
          onClose={onClose}
          aria-labelledby={BUTTON_ID}
          onMouseLeave={onClose}
          as="section"
          className="head-popout head--iconbar-popout"
        >
          <MiniCart onClose={onClose} />
        </PopOver>
      </Fade>
    </Box>
  )
}

export default MiniCartDropdown
