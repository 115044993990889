// dependencies
import React from 'react'
import {
  TextField,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Box,
  Button,
  styled,
} from '@mui/material'
import RTGLink from '@shared/link'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
// hooks
import { useModalsContext } from '@context/modalContext'
import { useCreateAccountForm } from './hooks'
// components
import { RequiredFieldsMessage, PasswordRequirements, FormGrid, InputGrid, SubmitButton } from '../Common'

export const MODAL_ID = 'MODAL/CREATE_NEW_ACCOUNT'

const StyledRTGLink = styled(RTGLink)`
  text-decoration: underline;
  color: ${props => props.theme.palette.primary.main};
  text-transform: unset;
`

const NoPaddingInputGrid = styled(InputGrid)`
  padding-left: 0 !important;
  padding-right: 14px !important;
`

const NoPaddingGrid = styled(Grid)`
  padding-left: 0 !important;
  padding-right: 14px !important;
`

const StyledHaveAnAccountText = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const HaveAnAccountButton = styled(Button)`
  text-transform: none;
  text-decoration: underline;
  min-width: auto !important;
  padding: 6px 0 6px 6px;
`

export const CreateNewAccount = () => {
  const { dispatch, actions } = useModalsContext()
  const {
    formik,
    showPassword,
    onVisibilityToggle,
    loading,
    errorMessage,
    handleMouseDownPassword,
  } = useCreateAccountForm()
  const { firstName, lastName, email, password, zipcode, terms, emailList } = formik.values

  const isDisabled = !(firstName && lastName && email && password && zipcode)

  return (
    <Box>
      <RequiredFieldsMessage />
      <FormGrid spacing={2} component="form" container onSubmit={formik.handleSubmit}>
        <NoPaddingGrid item xs={12} sm={12} md={6}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="firstName"
            autoComplete="given-name"
            name="firstName"
            label="First Name"
            required
            value={firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={6}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="lastName"
            autoComplete="family-name"
            name="lastName"
            label="Last Name"
            required
            value={lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="zipcode"
            autoComplete="postal-code"
            name="zipcode"
            label="Delivery Zip Code"
            required
            value={zipcode}
            onChange={formik.handleChange}
            error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
            helperText={formik.touched.zipcode && formik.errors.zipcode}
          />
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="email"
            autoComplete="email"
            name="email"
            label="Email"
            required
            value={email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="password"
            autoComplete="password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            required
            value={password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onVisibilityToggle('password')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12} style={{ paddingTop: 0, fontSize: '14px' }}>
          <PasswordRequirements success={!(formik.touched.password && formik.errors.password)} />
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <Box display="flex" flexDirection="row">
            <FormControlLabel
              sx={{ alignItems: 'flex-start', marginRight: 0 }}
              control={
                <Checkbox
                  sx={{
                    paddingTop: 0,
                    '& svg': {
                      color: t => t.palette.primary.main,
                    },
                  }}
                  checked={terms}
                  onChange={formik.handleChange}
                  name="terms"
                  color="primary"
                />
              }
            />
            <Typography variant="body1">
              {'I acknowledge that by creating an account, I am agreeing with the Rooms To Go '}
              <StyledRTGLink
                data={{
                  target: '_blank',
                  url: 'https://legal.roomstogo.com/rtg-online#contract-7siltltgm',
                  category: 'terms of use',
                  action: 'click',
                  label: 'Terms',
                }}
              >
                Terms
              </StyledRTGLink>
              {' and '}
              <StyledRTGLink
                data={{
                  target: '_blank',
                  url: 'https://legal.roomstogo.com/rtg-online#contract-fxauo3tp2',
                  category: 'online privacy notice',
                  action: 'click',
                  label: 'Privacy',
                }}
              >
                Privacy Notice
              </StyledRTGLink>
              .
            </Typography>
          </Box>
          {formik.touched.terms && formik.errors.terms && (
            <Box sx={{ paddingLeft: '30px' }}>
              <Typography color="error" style={{ fontSize: '14px' }}>
                {formik.errors.terms}
              </Typography>
            </Box>
          )}
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <Box display="flex" flexDirection="row">
            <FormControlLabel
              sx={{ alignItems: 'flex-start', marginRight: 0 }}
              control={
                <Checkbox
                  sx={{
                    paddingTop: 0,
                    '& svg': {
                      color: t => t.palette.primary.main,
                    },
                  }}
                  checked={emailList}
                  onChange={formik.handleChange}
                  name="emailList"
                  color="primary"
                />
              }
            />
            <Typography variant="body1">
              {'I want to receive Rooms To Go email promotions and newsletters. '}
            </Typography>
          </Box>
        </NoPaddingGrid>

        {errorMessage && (
          <NoPaddingGrid item xs={12} sm={12} md={12}>
            <Typography color="error" style={{ fontSize: '14px' }}>
              {errorMessage}
            </Typography>
          </NoPaddingGrid>
        )}
        <NoPaddingInputGrid item xs={12} sm={12} md={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <SubmitButton
              disabled={isDisabled}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              type="submit"
            >
              Create Account
            </SubmitButton>
          )}
        </NoPaddingInputGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <StyledHaveAnAccountText>
            Have an account?
            <HaveAnAccountButton variant="text" onClick={() => dispatch({ type: actions.login })}>
              Log in here
            </HaveAnAccountButton>
            .
          </StyledHaveAnAccountText>
        </NoPaddingGrid>
      </FormGrid>
    </Box>
  )
}
