import React from 'react'
import { Box, Grid, Typography, TextField, CircularProgress } from '@mui/material'
import { useForgotPasswordForm } from './hooks'
import { RequiredFieldsMessage, BackButton, FormGrid, SubmitButton, InputGrid } from '../Common'

export const MODAL_ID = 'MODAL/FORGOT_PASSWORD'

export const ForgotPassword = () => {
  const { formik, errorMessage, loading, goBackToLogin } = useForgotPasswordForm()
  return (
    <Box>
      <BackButton onClick={goBackToLogin} label="Back To Login" />
      <RequiredFieldsMessage />
      <FormGrid spacing={2} component="form" container onSubmit={formik.handleSubmit}>
        <InputGrid item xs={12} sm={12} md={12} style={{ paddingLeft: 0, paddingRight: '14px' }}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            type="email"
            label="Email"
            required
            id="email"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </InputGrid>
        {errorMessage && (
          <Grid item xs={12} sm={12} md={12} style={{ paddingLeft: 0, paddingRight: '14px' }}>
            <Typography color="error" style={{ fontSize: '14px' }}>
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <InputGrid item xs={12} sm={12} md={12} style={{ paddingLeft: 0, paddingRight: '14px' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <SubmitButton
              disabled={!formik.values.email}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              type="submit"
            >
              SEND
            </SubmitButton>
          )}
        </InputGrid>
      </FormGrid>
    </Box>
  )
}
