import React, { useState, useEffect, useCallback } from 'react'
import { AsYouType } from 'libphonenumber-js'

const asYouType = new AsYouType('US')

const usePhoneNumber = () => {
  const [phone, _setPhone] = useState('')
  const [isValid, setIsValid] = useState(false)
  const phoneNumber = phone.replace(/\D+/g, '')

  const setPhone = useCallback(newValue => {
    asYouType.reset()
    _setPhone(asYouType.input(newValue.replace(/\D+/g, '')))
  }, [])

  const onKeyDown = useCallback(
    e => {
      if (e.keyCode === 8 || e.keyCode === 46) {
        // ? deletes the last Parenthesis and the last Number
        if (phone.slice(-1) === ')') {
          e.preventDefault()
          setPhone(phone.replace(/\D/g, '').slice(0, -1))
        }
      }
      // ? not spaces
      if (e.keyCode === 32) {
        e.preventDefault()
      }

      if (asYouType.isValid() && asYouType.isPossible() && !(e.keyCode === 8 || e.keyCode === 46)) {
        e.preventDefault()
      }
    },
    [phone, setPhone],
  )

  useEffect(() => {
    setIsValid(asYouType.isValid())
  }, [phone])

  return { phone, onKeyDown, setPhone, isValid, phoneNumber }
}

export default usePhoneNumber
