/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/static-property-placement */
import { useEffect } from 'react'
import { useStore } from 'react-redux'

import getInjectors from './sagaInjectors'

const useInjectSaga = ({ key, saga, mode }) => {
  const store = useStore()

  useEffect(() => {
    const injectors = getInjectors(store)
    injectors.injectSaga(key, { saga, mode })

    return () => {
      injectors.ejectSaga(key)
    }
  }, [])
}

// eslint-disable-next-line import/prefer-default-export
export { useInjectSaga }
