import React from 'react'
import { makeStyles, Button, Box } from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  backBtn: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '1rem',
      left: '1rem',
    },
  },
}))

const BackButton = ({ onClick, label }) => {
  const classes = useStyles()
  return (
    <Box className={classes.backBtn}>
      <Button onClick={onClick} startIcon={<ChevronLeft />} variant="text">
        {label}
      </Button>
    </Box>
  )
}

BackButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
}

export default BackButton
