import React, { createContext, useContext, useState, useMemo, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { navigate } from 'gatsby'
import { useAuthState } from 'react-firebase-hooks/auth'
import { initialize } from '../../auth/firebase'

/**
 * @typedef {Object} FirebaseContext
 */

/**
 * Firebase Context
 * @type {FirebaseContext}
 * */
const initialContextValue = {
  authenticationId: {},
  auth: {},
}

const FirebaseContext = createContext(initialContextValue)

export const FirebaseContextProvider = ({ children }) => {
  const auth = initialize()
  const [user, loading] = useAuthState(auth)
  const [token, setToken] = useState()
  useEffect(() => {
    if (auth) {
      const path = typeof window !== 'undefined' && _get(window, 'location.pathname', '')
      auth.onAuthStateChanged(userState => {
        if (!userState && path.includes('/account/')) {
          navigate('/')
        }
      })
      auth.onIdTokenChanged(async userData => {
        if (userData) {
          const tokenResponse = await userData.getIdToken()
          setToken(tokenResponse)
        }
      })
    }
  }, [auth])

  /**
   * Firebase Context
   * @type {FirebaseContext}
   * */
  const context = { auth, user, loading, token }

  return <FirebaseContext.Provider value={context}>{children}</FirebaseContext.Provider>
}

FirebaseContextProvider.propTypes = {
  children: PropTypes.node,
}

/**
 * @returns {FirebaseContext}
 */
export const useFirebaseContext = () => useContext(FirebaseContext)
