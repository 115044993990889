import React, { useMemo, useEffect, useState } from 'react'
import { getFinancePlans } from '@helpers/finance'
import { makeStyles } from '@material-ui/core'
import { get } from 'lodash'
import InfoModal from '@shared/info-modal'

const highestPriorityFinancePlan = getFinancePlans(null, null, true)[0]

const useStyles = makeStyles(theme => ({
  html: {
    padding: theme.spacing(2),
  },
}))

const LearnMoreModal = () => {
  const classes = useStyles()
  const [showFinanceModal, setShowFinanceModal] = useState(false)
  const modalHtml = useMemo(() => get(highestPriorityFinancePlan, 'promoMessage.childMarkdownRemark.html', null), [])

  useEffect(() => {
    Array.from(document.getElementsByClassName('synchrony-prequal-learn-more')).forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault()
        e.stopPropagation()
        setShowFinanceModal(true)
      })
    })
  }, [])

  return (
    showFinanceModal &&
    modalHtml && (
      <InfoModal
        label="Rooms To Go Credit Options"
        mdlClass="rtg-credit-modal"
        shouldShowModal={showFinanceModal}
        closeModal={() => setShowFinanceModal(false)}
      >
        <div
          className={classes.html}
          dangerouslySetInnerHTML={{
            __html: modalHtml,
          }}
        />
      </InfoModal>
    )
  )
}

export default LearnMoreModal
