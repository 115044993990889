import React, { useRef, useState } from 'react'

import { useSelector } from 'react-redux'
import { Badge, Menu } from '@mui/material'
import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined'
import CloseIcon from '@mui/icons-material/Close'

import { RightSideBtn } from '@components/header/mobile/Styled'
import ShippingChangeZip from '@components/header/shipping-change-zip'

const BUTTON_ID = 'mobile-header-shop-deliver-menu-btn'
const MENU_ID = 'mobile-header-shop-deliver-menu'

const Delivery = () => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const shippingAddress = useSelector(s => s.location.shipping_address)
  const state = shippingAddress ? shippingAddress.substr(shippingAddress.length - 2) : null

  const onClickOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const buttonAriaProps = {
    'aria-haspopup': 'true',
    'aria-label': 'Change Shop and Deliver to location',
    ...(open
      ? {
          'aria-controls': MENU_ID,
          'aria-expanded': 'true',
        }
      : {}),
  }

  return (
    <>
      <RightSideBtn
        ref={anchorRef}
        color="primary"
        size="large"
        aria-label="Delivery"
        edge="end"
        onClick={onClickOpen}
        {...buttonAriaProps}
      >
        <Badge color="primary" badgeContent={open ? null : state}>
          {open ? <CloseIcon /> : <LocalShippingIcon />}
        </Badge>
      </RightSideBtn>
      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorRef.current}
        MenuListProps={{
          'aria-labelledby': BUTTON_ID,
          as: 'div',
        }}
      >
        <ShippingChangeZip />
      </Menu>
    </>
  )
}

export default Delivery
