import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Segment } from '@components/integrations/Segment'
import LearnMoreAboutFinanceModal from '@components/shared/LearnMoreAboutFinanceModal'
import Favicons from '../shared/favicons'
import BrowserDetect from '../browser-detect'
import LayoutChildren from './layout-children'
import '../../assets/css/global.sass'

export default function LAYOUT({ data, cartQuantity, isProductPage }) {
  const segmentKey = process.env.GATSBY_SEGMENT_API_KEY
  let pathName = ''

  if (typeof window !== 'undefined' && window.location) {
    pathName = window.location.pathname
  } else if (data && data.location) {
    pathName = data.location.pathname
  }

  const getAlternatePathName = () => {
    const rootPath = 'https://www.roomstogo.pr'
    return rootPath + pathName
  }

  const metaInfo = [
    {
      name: 'google-site-verification',
      content: 'aHOTOMvUUGWQxbjXuOdF3co0LDA86iT0sjiylVwfrk4',
    },
    {
      name: 'google-site-verification',
      content: 'TGVtmWUFiuWkITFKGdw_cVZrhYlA-y3MIcMEhc-yDFw',
    },
    {
      name: 'google-site-verification',
      content: 'OWEZoxMRhdioV38iOk2ueJyCZSrsVUpUcho22mYuiRw',
    },
  ]

  if (!isProductPage) {
    metaInfo.unshift({
      name: 'description',
      content: 'Welcome to Rooms To Go!',
    })
  }

  return (
    <div>
      {segmentKey && <Segment apiKey={segmentKey} />}
      <a className="skip" href="#content">
        Skip to Main Content
      </a>
      <BrowserDetect />
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        link={[
          {
            rel: 'alternate',
            hrefLang: 'en-pr',
            href: getAlternatePathName(),
          },
        ]}
        meta={metaInfo}
        script={[
          {
            type: 'text/javascript',
            innerHTML: 'window.dataLayer = window.dataLayer || [];',
          },
        ]}
        bodyAttributes={{
          class: 'has-navbar-fixed-top',
        }}
      />
      <Favicons />
      <LayoutChildren data={data} cartQuantity={cartQuantity} />
      <LearnMoreAboutFinanceModal />
    </div>
  )
}

LAYOUT.propTypes = {
  data: PropTypes.object,
  cartQuantity: PropTypes.number,
  isProductPage: PropTypes.bool,
}
