import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { Button } from '@mui/material'
import '../../assets/css/components/shared/location-fields.sass'

const LocationFields = ({ inputRef, error, addressInput, onChange, noAutoComplete, id }) => (
  <div className="location-fields">
    <label className="label-header" htmlFor={`location${id}`}>
      {error && (
        <div className="error">
          <p>
            *Please enter a valid {process.env.GATSBY_RTG_DIVISION_SUPPORTED === 'PR' ? 'Puerto Rico' : 'USA'} city and
            state, or zip code.
          </p>
        </div>
      )}
    </label>
    <input
      ref={inputRef}
      type="text"
      className={classNames(
        'input-field',
        {
          error,
        },
        'shipping',
      )}
      name="location"
      id={`location${id}`}
      aria-required="true"
      aria-invalid={!!error}
      placeholder="Enter City and State, or Zip Code"
      // placeholder={`Example: ${getRegionZone().region === 'PR' ? 'Aguadilla, PR or 00603' : 'Atlanta, GA or 30312'}`}
      value={addressInput}
      onChange={onChange}
      autoComplete={noAutoComplete ? 'off' : ''}
    />
    <Button className="shipping" variant="contained" color="primary" fullWidth size="large" type="submit">
      Change Location
    </Button>
  </div>
)

LocationFields.propTypes = {
  addressInput: PropTypes.any,
  error: PropTypes.any,
  id: PropTypes.any,
  inputRef: PropTypes.any,
  noAutoComplete: PropTypes.any,
  onChange: PropTypes.func,
}

export default LocationFields
