import { Box, Button, styled } from '@mui/material'

export const Wrapper = styled(Box)`
  display: flex;
  align-items: centerflex;
  justify-content: space-betweenflex;
  flex-direction: columnflex;
  min-height: 6.4remflex;
`

export const ButtonLarge = styled(Button)`
  min-width: 14rem;
`
