import React from 'react'

import PropTypes from 'prop-types'
import { Grid, Box, styled, Typography, Button } from '@mui/material'

import StrapiLink, { ButtonLink } from '@templates/strapi-cms/content-types/Link'

const Image = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Banner = styled(Grid)`
  .link-wrapper {
    position: relative;
    padding-bottom: 80%;
    width: 100%;
    display: flex;

    &:focus {
      outline: none !important;
    }

    .sub-category-link {
      font-size: 0.9rem;
      font-weight: 600;
      text-align: center;
    }

    &:hover .sub-category-link,
    &:focus .sub-category-link {
      color: ${p => p.theme.palette.primary.main};
      text-decoration: underline;
    }
  }
`

const LinkCaption = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  background-color: rgba(245, 245, 245, 0.6);
`

const LeftSideBanners = ({ bannerGroup = null, onClose }) => {
  if (!bannerGroup) {
    return null
  }
  const headingLink = bannerGroup?.HeadingLink?.Link
  const banners = (bannerGroup?.CategoryBanners || []).slice(0, 4)
  return (
    <Grid item container sm={6} spacing={2}>
      <Grid item sm={12}>
        <StrapiLink
          className="link heading"
          onClick={onClose}
          data-textid={headingLink?.id}
          style={{ paddingBottom: 0, display: 'inline' }}
          data={headingLink}
          trackingData={{
            category: 'navigation',
            action: 'link click',
            label: headingLink?.Title || headingLink?.DisplayText,
          }}
        />
      </Grid>

      {banners.map(({ CategoryBanner }, i) => {
        const image = CategoryBanner?.Image
        const link = CategoryBanner?.CategoryLink?.Link
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Banner item sm={6} key={`${image?.id}}-${i}}`}>
            <Button
              component={ButtonLink}
              onClick={onClose}
              className="link-wrapper"
              data-textid={link?.id}
              data={link}
              trackingData={{
                category: 'navigation',
                action: 'link click',
                label: link?.Title || link?.DisplayText,
              }}
            >
              <Image src={image?.url} />
              <LinkCaption>
                <Typography className="sub-category-link" data-textid={link?.id}>
                  {link?.DisplayText}
                </Typography>
              </LinkCaption>
            </Button>
          </Banner>
        )
      })}
    </Grid>
  )
}

LeftSideBanners.propTypes = {
  bannerGroup: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default LeftSideBanners
