import { navigate as gatsbyNavigate } from 'gatsby'
import { store } from '@redux/store'
import { setShowSearchResults } from '@redux/modules/global'

export const navigate = slug => {
  if (store.getState().global.showSearchResults) {
    store.dispatch(setShowSearchResults(false))
  }
  gatsbyNavigate(slug)
}

export default navigate

export const canClientSideRoute = (locationPath, linkPath) => {
  const fullRoutePath = '/furniture/product/'
  const matchesFullRoutePath = [locationPath, linkPath].find(path => path?.match(fullRoutePath))
  return !matchesFullRoutePath
}
