import React from 'react'
import { Box, Button, styled } from '@mui/material'
import { useModalsContext } from '@context/modalContext'

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2, 0)};
`
const CloseBtn = styled(Button)`
  min-width: 12rem;
  font-weight: 600;
`

export const EmailSentID = 'MODAL/EMAIL_SENT'

export const EmailSent = () => {
  const { dispatch, actions } = useModalsContext()
  return (
    <Wrapper>
      <CloseBtn
        onClick={() => dispatch({ type: actions.close })}
        color="primary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Ok
      </CloseBtn>
    </Wrapper>
  )
}
