import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { Collapse, List, Box, Typography, Icon } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { ButtonItem, ListItemText, FooterLink } from '../Styled'
import SubItem from './SubItem'

const ListItem = ({ onDrawerClose, data = null }) => {
  const [open, setOpen] = useState(false)

  const PrimaryLink = data?.PrimaryLink?.Link
  const NavigationFooter = (data?.NavigationFooter || []).slice(0, 4)
  const NavLinks = data?.NavLinks

  const onExpand = e => {
    e.stopPropagation()
    setOpen(!open)
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        category: 'navigation',
        action: 'open menu',
        label: PrimaryLink?.DisplayText,
      })
    }
  }

  const onLinkClick = e => {
    e.stopPropagation()
    onDrawerClose()
  }

  return (
    <>
      <ButtonItem onClick={onExpand} divider>
        <ListItemText
          data-testid={PrimaryLink?.id}
          data={PrimaryLink}
          trackingData={{
            category: 'navigation',
            action: 'link click',
            label: PrimaryLink?.DisplayText,
          }}
          onClick={onLinkClick}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ButtonItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          {NavLinks?.map(subNav => (
            <SubItem data={subNav} onDrawerClose={onDrawerClose} key={subNav?.LinkList?.id} />
          ))}
        </List>
        <Box sx={{ pl: 4, py: 2 }}>
          {NavigationFooter.map(item => {
            const { Text, Caption, MaterialIcon = 'flag' } = item?.BannerButton || {}
            const { id = null, InternalUrl = '/' } = item?.BannerButton?.Link?.Link || {}
            return (
              <Box key={`mobile-nav-footer-${id}}`} sx={{ pb: 1 }}>
                <FooterLink
                  data={{ InternalUrl, id, Title: Text }}
                  trackingData={{
                    category: 'navigation footer',
                    action: 'link click',
                    label: Text,
                  }}
                  onClick={onLinkClick}
                >
                  <Icon baseClassName="material-icons-outlined" fontSize="2rem">
                    {MaterialIcon}
                  </Icon>
                  <span className="text">
                    <Typography className="title" as="span">
                      {Text}
                    </Typography>
                    <Typography className="caption" variant="caption" as="span">
                      {Caption}
                    </Typography>
                  </span>
                </FooterLink>
              </Box>
            )
          })}
        </Box>
      </Collapse>
    </>
  )
}

ListItem.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  data: PropTypes.object,
}

export default ListItem
