import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import components from './components'

// eslint-disable-next-line import/no-mutable-exports
let config = createTheme()
config = createTheme({
  palette: { ...palette },
  typography: { ...typography },
  components: { ...components },
  breakpoints: {
    values: {
      xs: 0,
      sm: 639,
      md: 1024,
      lg: 1480,
      xl: 1920,
    },
  },
})
const MUI_THEME = responsiveFontSizes(config)
export default MUI_THEME
