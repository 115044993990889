export const lockBodyScroll = () => {
  if (typeof window !== 'undefined') {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    document.body.style.position = 'fixed'
    document.body.style.overflow = 'hidden'
    document.body.style.top = `-${scrollY}`
    // document.body.style['overscroll-behavior'] = 'none' // Will SUPPORT on Safari 16
  }
}

export const unlockBodyScroll = () => {
  if (typeof window !== 'undefined') {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.overflow = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
    // document.body.style['overscroll-behavior'] = '' // Will SUPPORT on Safari 16
  }
}

export const onScroll = () => {
  if (typeof window !== 'undefined') {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
  }
}
