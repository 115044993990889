import React, { useState, useRef, useEffect } from 'react'

import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { styled, Button, Paper, useMediaQuery } from '@mui/material'
import classNames from 'classnames'

import { lockBodyScroll, unlockBodyScroll } from '@helpers/bodyscroll'
import Megamenu from './Megamenu'

const getID = (value = '', suffix = '') => {
  const id = value.toLowerCase().replace(/\s/g, '-')
  return suffix ? `${id}-${suffix}` : id
}

const Popover = styled(Paper)`
  position: fixed;
  height: auto;
  width: 1250px;
  border-radius: 0;
  border: solid 1px grey;
  background-color: ${p => p.theme.palette.background.paper};
  padding: ${p => {
    const x = p.theme.spacing(2)
    return `${x} 0 ${x} ${x}`
  }};
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1300px) {
    left: 0 !important;
    transform: translateX(0);
    width: 100vw;
  }
`

const ListItem = styled('li')`
  padding: 0 6px;

  .nav-btn {
    padding: 12px;
    line-height: 1;
    font-size: 1rem;
    border-radius: 0;
    position: relative;
    display: block;
    font-weight: 400;
    color: ${p => p.theme.palette?.common?.black};
    text-decoration: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      left: 0;
      transition: background-color 0.4s ease;
      background-color: transparent;
    }

    &.active {
      &::after {
        background-color: ${p => p.theme.palette?.warning?.main};
      }
    }

    &.open {
      &::after {
        background-color: ${p => p.theme.palette?.primary?.main};
      }
    }

    &:hover {
      color: ${p => p.theme.palette?.primary?.main};
      text-decoration: none;
    }
  }
`

const isActive = link => {
  if (link && typeof window !== 'undefined') {
    return window.location.href.includes(link?.InternalUrl)
  }
  return false
}

const NavigationItem = ({ data, menuRef, onSetBackdrop }) => {
  const isMedium = useMediaQuery('(max-width: 1300px)')
  const [open, setOpen] = useState(false)

  const x = isMedium ? '0%' : '-50%'
  const contentID = data?.id
  const primaryLink = data?.PrimaryLink?.Link
  const menuID = getID(primaryLink?.DisplayText, 'megamenu')
  const buttonID = getID(primaryLink?.DisplayText, 'category-navigation-item-link')

  const onClose = () => {
    unlockBodyScroll()
    onSetBackdrop(false)
    setOpen(false)
  }

  const onClickOpen = () => {
    lockBodyScroll()
    onSetBackdrop(true)
    setOpen(true)
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        category: 'navigation',
        action: 'open menu',
        label: primaryLink?.DisplayText,
      })
    }
  }

  const onClickAway = e => {
    if (e.target.id) {
      if (e.target.id === buttonID) return

      if (e.target.id.includes('category-navigation-item-link')) {
        setOpen(false)
        return
      }
    }
    onClose()
  }

  const onKeyDown = e => {
    if (e.key === 'Escape' || (e.shiftKey && e.key === 'Tab')) {
      onClose()
    }
  }

  const buttonAriaProps = {
    'aria-haspopup': 'true',
    'aria-label': primaryLink?.DisplayText,
    ...(open
      ? {
          'aria-controls': menuID,
          'aria-expanded': 'true',
        }
      : {}),
  }

  return (
    <ListItem data-testid={contentID}>
      <Button
        className={classNames('nav-btn', {
          open,
          active: isActive(primaryLink),
        })}
        onClick={onClickOpen}
        id={buttonID}
        variant="text"
        data-testid={primaryLink?.id}
        ref={menuRef}
        role="button"
        data={primaryLink}
        {...buttonAriaProps}
        onKeyDown={onKeyDown}
      >
        {primaryLink?.DisplayText}
      </Button>

      {open && (
        <Popover
          key="menuitem"
          component={motion.section}
          initial={{ opacity: 0, y: -60, x }}
          animate={{
            opacity: 1,
            y: 0,
            x,
          }}
          aria-labelledby={buttonID}
          id={menuID}
          open={open}
          onClose={onClose}
          onKeyDown={onKeyDown}
        >
          <Megamenu data={data} onClickAway={onClickAway} onClose={onClose} />
        </Popover>
      )}
    </ListItem>
  )
}

NavigationItem.propTypes = {
  data: PropTypes.object.isRequired,
  menuRef: PropTypes.object.isRequired,
  onSetBackdrop: PropTypes.func.isRequired,
}

export default NavigationItem
