import { call, put, takeLatest } from 'redux-saga/effects'
import { emailSignup } from '@services/email'
import { getLocation } from '@services/location'
import { announce } from '@helpers/aria-announce'
import { getCurrentLocation } from '@helpers/geo-location'
import { identifyUser } from '@components/integrations/Segment'
import { EMAIL_SIGNUP, setLoading, setError, setSuccess } from './modules/email-subscribe'

export function* emailSubscribe(action) {
  yield put(setSuccess(false))
  yield put(setError(false))
  yield put(setLoading(true))
  const { email, zip: zipcode } = action
  const loc = yield call(getLocation, zipcode)
  const storenumber =
    loc && loc.response && loc.response.distribution_index
      ? loc.response.distribution_index
      : location.distribution_index

  const date = new Date().toISOString()
  const location = getCurrentLocation()
  const subsourcecode = 'rtgfooter'
  try {
    // Segment Identify call
    identifyUser({
      email,
      zipcode,
      subsourcecode,
    })
    yield call(emailSignup, {
      email,
      sourcecode: 'ONLINE',
      sourcekey: 'ONLINE',
      subsourcecode,
      sourcedate: date,
      userid: 'rtg-web-user',
      zipcode,
      storenumber,
      signup: 'true',
    })
    yield put(setSuccess(true))
    const message = 'Signed up for emails!'
    announce(`Form Submitted: ${message}`)
  } catch (err) {
    yield put(setError(true))
    const message = '*Unable to sign up for emails.'
    announce(`Form Error: ${message}`)
  } finally {
    yield put(setLoading(false))
  }
}

export default function* rootSaga() {
  yield takeLatest(EMAIL_SIGNUP, emailSubscribe)
}
