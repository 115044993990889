import { useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import { useLoginMutation, useAuthErrorMessage } from '@hooks/auth'
import { useModalsContext } from '@context/modalContext'
import usePasswordVisibility from '@hooks/usePasswordVisibility'
import { useNotifications } from '@context/notifications'
import * as yup from 'yup'
import { addToDataLayer } from '@helpers/google-tag-manager'

export const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string('Enter your password'),
})

export const useLoginForm = () => {
  const { dispatch, actions } = useModalsContext()
  const { enqueue } = useNotifications()

  const onSuccess = useCallback(
    newUser => {
      dispatch({ type: actions.close })
      addToDataLayer('sign_in', 'accounts', 'click', 'uid', newUser._id)
      setTimeout(() => {
        dispatch({ type: actions.reset })
        enqueue({ variant: 'filled', body: 'You are now logged in!' })
      }, 500)
    },
    [dispatch, actions, enqueue],
  )

  const { mutate, error, isLoading, data } = useLoginMutation({ onSuccess })
  const { errorMessage, clearMessage } = useAuthErrorMessage(error)
  const { showPassword, onVisibilityToggle } = usePasswordVisibility()

  const onSubmit = useCallback(
    values => {
      clearMessage()
      mutate({ values })
    },
    [clearMessage, mutate],
  )

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
  })

  return { showPassword, onVisibilityToggle, formik, loading: isLoading, errorMessage }
}
