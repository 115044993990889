// dependencies
import React, { useCallback, useEffect } from 'react'
import { makeStyles, Dialog, IconButton, Typography, useMediaQuery, useTheme, Grid } from '@material-ui/core'
import { styled } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'
// contexts
import { useModalsContext } from '@context/modalContext'
// helpers
import { lockBodyScroll, unlockBodyScroll } from '@helpers/bodyscroll'
// assets
import RTGLogo from '@shared/svgs/logo'
import ModalImage from './ModalImage'

const StyledDialog = styled(Dialog)({
  zIndex: '7000 !important',
})

const useStyles = makeStyles(theme => ({
  modalPapper: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '900px',
    },
  },
  boxShadow: theme.shadows[5],
  modalWrapper: {
    minHeight: '756px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxWidth: '100vw',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    outline: 'none !important',
    '& *': {
      outline: 'none !important',
    },
  },
  contentWrapper: {
    padding: theme.spacing(4, 9, 4, 12),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 4, 4, 4),
    },
  },
  closeBtn: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '&:focus': {
      backgroundColor: theme.palette.grey[100],
    },
    '& img': {
      width: '22px',
      height: '22px',
    },
  },
  leftSide: {
    position: 'relative',
    width: '100%',
  },
  blueBg: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    '& img': {
      width: '150px',
    },
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '299px',
    '& img': {
      left: '40px',
      top: '50%',
      position: 'absolute',
      width: '90%',
    },
  },
  heading: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
    '& h4': {
      textAlign: 'center',
      paddingBottom: theme.spacing(1),
    },
    '& h6': {
      textAlign: 'center',
    },
  },
  mobileLogo: {
    height: 'auto',
    width: '100%',
    position: 'absolute',
    top: '20px',
    left: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    '& button': {
      minWidth: '8rem',
    },
    '& .MuiButton-outlined': {
      margin: '0 30px 0 15px',
    },
  },
}))

const AccountModals = () => {
  const theme = useTheme()
  const { modal, dispatch, actions } = useModalsContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  const onClose = useCallback(() => {
    dispatch({ type: actions.close })
  }, [dispatch, actions])

  return (
    <StyledDialog
      maxWidth="md"
      fullWidth
      PaperProps={{
        className: classes.modalPapper,
      }}
      fullScreen={isMobile}
      open={modal?.isOpen || false}
      onClose={onClose}
      aria-labelledby={modal?.titleID}
      aria-describedby={modal?.descriptionID}
    >
      <Grid container className={classes.modalWrapper}>
        {!isMobile && (
          <Grid item md={3} className={classes.leftSide}>
            <div className={classes.blueBg} />
            <div className={classes.imageWrapper}>
              <ModalImage />
            </div>
          </Grid>
        )}
        <Grid item md={9} className={classes.contentWrapper}>
          {isMobile && (
            <div className={classes.mobileLogo}>
              <RTGLogo width={220} uniqueNameForId="mobileGrid" />
            </div>
          )}
          <div className={classes.heading}>
            <IconButton
              size="small"
              type="button"
              className={classes.closeBtn}
              tabIndex="0"
              value="Close"
              aria-label="Close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h4" id={modal?.titleID}>
              {modal.title}
            </Typography>
            <Typography variant="subtitle1" id={modal?.descriptionID}>
              {modal.description}
            </Typography>
          </div>
          <div className={classes.content}>{modal?.component && <modal.component />}</div>
        </Grid>
      </Grid>
    </StyledDialog>
  )
}

export default AccountModals
