import React from 'react'

import LocationIcon from '@mui/icons-material/LocationOnOutlined'
import LocationActiveIcon from '@mui/icons-material/LocationOn'

import { LeftSideBtn } from '@components/header/mobile/Styled'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'

const StoreLocator = () => (
  <LeftSideBtn
    component={ButtonLink}
    size="large"
    edge="start"
    color="primary"
    aria-label="Store locator"
    data={{
      InternalUrl: '/stores',
      Title: 'Find a Store',
    }}
    trackingData={{ category: 'header', action: 'store locator click', label: 'store locator' }}
  >
    {typeof window !== 'undefined' && window.location.pathname === '/stores' ? (
      <LocationActiveIcon />
    ) : (
      <LocationIcon />
    )}
  </LeftSideBtn>
)

export default StoreLocator
