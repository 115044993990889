import React, { useState, useCallback, useEffect } from 'react'
import { TextField } from '@mui/material'
import usePhoneNumber from '@hooks/usePhoneNumber'
import PropTypes from 'prop-types'

const PhoneInput = ({ value, onChange, ...props }) => {
  const [initialized, setInitialized] = useState(false)
  const { phone, setPhone, onKeyDown } = usePhoneNumber()

  useEffect(() => {
    if (!initialized && value) {
      setPhone(value)
      setInitialized(true)
    }
  }, [value, initialized, setPhone])

  const onPhoneChange = useCallback(
    e => {
      setPhone(e.target.value)
      setTimeout(() => onChange(e), 220)
    },
    [onChange, setPhone],
  )

  return <TextField value={phone} onKeyDown={onKeyDown} onChange={onPhoneChange} {...props} />
}

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PhoneInput
