import { styled, Button, IconButton } from '@mui/material'

export const NavButton = styled(Button)`
  text-transform: unset;
  margin-left: ${p => p.theme.spacing(2)};
  text-underline-offset: 2px;
  font-size: 0.875rem;

  .text-wrapper {
    display: block;
    text-align: left;
    color: ${p => p.theme.palette.primary.dark};
  }

  .btn-text {
    display: block;
    line-height: 1.2rem;
  }

  .underline {
    text-decoration: underline;
  }

  svg {
    color: ${p => p.theme.palette.primary.dark};
  }

  .MuiButton-endIcon {
    margin-top: 1.25rem; // 20px
    margin-left: 0.188rem; // 3px
  }

  ${p => p.theme.breakpoints.down('lg')} {
    .text-wrapper,
    .btn-text {
      display: none;
    }

    .MuiButton-startIcon {
      margin-right: 0;
    }

    .MuiButton-endIcon {
      margin-top: 1.063rem; // 17px
      margin-right: -0.188rem; // 3px
    }
  }
`

export const HeaderIconBtn = styled(IconButton)(({ theme }) => ({
  svg: {
    color: theme.palette.primary.dark,
  },
  [theme.breakpoints.down('lg')]: {
    '& > span': {
      display: 'none',
    },
  },
}))
