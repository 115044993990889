import React, { useRef, useState } from 'react'

import { Menu, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined'
import ShippingChangeZip from '../../shipping-change-zip'
import { NavButton } from '../Styled'

const BUTTON_ID = 'header-shop-deliver-menu-btn'
const MENU_ID = 'header-shop-deliver-menu'

const ShopAndDeliverTo = () => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const shippingAddress = useSelector(s => s.location.shipping_address)

  const onClickOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const endIconStyles = { fontSize: '1.4rem' }
  const startIconStyles = { fontSize: '2rem !important' }

  const buttonAriaProps = {
    'aria-haspopup': 'true',
    'aria-label': 'Change Shop and Deliver to location',
    ...(open
      ? {
          'aria-controls': MENU_ID,
          'aria-expanded': 'true',
        }
      : {}),
  }

  return (
    <>
      <NavButton
        size="medium"
        ref={anchorRef}
        id={BUTTON_ID}
        data-testid="changeShopDeliverToLocation"
        endIcon={<KeyboardArrowDownIcon sx={endIconStyles} />}
        startIcon={<LocalShippingIcon sx={startIconStyles} />}
        onClick={onClickOpen}
        {...buttonAriaProps}
      >
        <span className="text-wrapper">
          <span className="btn-text">Shop & Deliver To</span>
          {typeof window !== 'undefined' ? (
            <span className="btn-text underline">{shippingAddress}</span>
          ) : (
            <Skeleton className="btn-text" variant="text" />
          )}
        </span>
      </NavButton>
      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorRef.current}
        MenuListProps={{
          'aria-labelledby': BUTTON_ID,
          as: 'div',
        }}
      >
        <ShippingChangeZip />
      </Menu>
    </>
  )
}

export default ShopAndDeliverTo
