import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'

const radialGradient = (innerColor, outerColor) =>
  `radial-gradient(50% 50% at 50% 50%, ${innerColor} 0%, ${outerColor} 100%)`

// GLOBAL STYLES
export const PRODUCT_CAROUSEL = styled('div')(({ theme }) => ({
  // SWIPER.JS STYLES
  '.swiper-container': {},
  '.swiper-wrapper': {
    alignItems: 'center',
    paddingBottom: '1.5rem',
    transition: '0.25s all linear !important',
  },
  '.swiper-button-lock, .swiper-button-disabled': {
    display: 'none',
  },
  '.swiper-button-prev, .swiper-button-next': {
    '&::before, &::after': { display: 'none' },
    marginTop: 0,
    width: 43,
    height: 43,
    button: {
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(247,247,247,0.5)',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  '.swiper-slide': {
    transform: 'scale(0.8)',
    transitionDelay: '0.15s',
    transition: '0.25s transform ease-in-out',
    [theme.breakpoints.up('md')]: {
      transform: 'scale(1)',
      maxWidth: 300,
      flex: 'auto',
    },
    '&-active': {
      zIndex: 1,
      height: '100%',
      transform: 'scale(1)',
    },
  },
  '.swiper-scrollbar': {
    backgroundColor: `rgba(0,83,160, 0.2)`,
    '&-drag': {
      backgroundColor: `rgba(0,83,160, 0.75)`,
    },
  },
  // MUI STYLES
  '.MuiCardMedia-root': {
    padding: '1rem',
    objectFit: 'contain',
    background: radialGradient(theme.palette.common.white, theme.palette.secondary.main),
    maxHeight: 250,
    minHeight: 250,
  },
}))

export const PRODUCT_TILE = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    borderRadius: 0,
    overflow: 'hidden',
    '.MuiCardMedia': {
      '&-root': {
        opacity: 1,
        transition: `0.1s all ease-in-out`,
      },
      '&-img': {
        width: 'auto',
        minWidth: 124,
        maxWidth: 300,
        height: 224,
      },
    },
    '.MuiCardActions-root': {
      position: 'absolute',
      mt: 4,
      bottom: 2,
      width: '100%',
      p: 0,
      button: {
        transition: '0.5s all cubic-bezier(0.15, 1.3, 0.3, 1)',
        opacity: 0,
        bottom: '-1rem',
        '&:hover': {
          textDecoration: 'underline',
        },
        '&.Mui-focusVisible': {
          transition: '0s all cubic-bezier(0.15, 1.3, 0.3, 1)',
          opacity: 1,
          bottom: '-2px',
          background: theme.palette.common.white,
          borderRadius: 0,
        },
      },
    },
    '.MuiCardContent-root': {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      opacity: 0,
      backgroundColor: 'rgba(255, 255, 255, 0)',
      transition: '0.25s all ease-in-out',
      p: {
        '&:hover': {
          color: theme.palette.primary.main,
          textDecoration: 'underline',
        },
      },
    },

    // ======== //
    // ON HOVER //
    // ======== //

    '&:hover': {
      '.MuiCardContent-root': {
        opacity: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      },
      '.MuiCardMedia-img': {
        filter: 'blur(2px)',
      },
      '.MuiCardActions-root': {
        button: {
          opacity: 1,
          bottom: 2,
        },
      },
      '.MuiCardActionArea-root .MuiCardActionArea-focusHighlight': {
        opacity: 0,
      },
    },
  },
}))
