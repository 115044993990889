/**
 *
 * Documentation:
 *
 * - [Button group](https://mui.com/material-ui/react-button-group/)
 * - [Buttons](https://mui.com/material-ui/react-button/)
 *
 * API:
 *
 * - [Button API](https://mui.com/material-ui/api/button/)
 * - inherits [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */

import palette from '../palette'

const MuiButton = {
  variants: [
    {
      props: { variant: 'link' },
      style: {
        color: palette.common.black,
        textTransform: 'uppercase',
        borderRadius: 0,
      },
    },
    {
      props: { variant: 'link', active: true },
      style: {
        borderBottom: `4px solid red`,
      },
    },
  ],
}

export default MuiButton
