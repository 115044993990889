import React from 'react'

import { Drawer, Toolbar, Box, styled } from '@mui/material'
import PropTypes from 'prop-types'

import MyAccountMenu from './MyAccountMenu'
import List from './List'

const Wrapper = styled(Box)`
  width: 90vw;
  max-width: 450px;
  padding-bottom: ${p => p.theme.spacing(4)};
  overflow-y: auto;
  background-color: ${p => p.theme.palette.background.paper};

  & a:focus {
    outline: none !important;
  }

  @media (max-width: 380px) {
    width: 100vw;
  }
`

const MobileDrawer = ({ open, onDrawerClose }) => (
  <Drawer
    anchor="left"
    open={open}
    onClose={onDrawerClose}
    ModalProps={{
      'aria-label': 'Main Menu',
      'aria-describedby': 'Browse products using the categories in this menu',
    }}
  >
    <Toolbar>
      <button type="button" onClick={onDrawerClose}>
        Close navigation
      </button>
    </Toolbar>
    <Wrapper>
      <MyAccountMenu onDrawerClose={onDrawerClose} />
      <List onDrawerClose={onDrawerClose} />
    </Wrapper>
  </Drawer>
)

MobileDrawer.propTypes = {
  open: PropTypes.bool,
  onDrawerClose: PropTypes.func,
}

export default MobileDrawer
