import { Set } from 'immutable'
import * as FavoritesService from '@services/favorites'

const RTG_FAVORITES = '__RTG_FAVORITES__'

// * INTERNALS

const addToStorage = sku => {
  if (!sku || typeof sku !== 'string') return
  const currentList = localStorage.getItem(RTG_FAVORITES)
  if (currentList) {
    localStorage.setItem(RTG_FAVORITES, `${currentList},${sku}`)
  } else {
    localStorage.setItem(RTG_FAVORITES, sku)
  }
}

const removeFromStorage = sku => {
  if (!sku || typeof sku !== 'string') return
  const currentList = localStorage.getItem(RTG_FAVORITES)
  if (currentList) {
    const items = Set(currentList.split(','))
    localStorage.setItem(RTG_FAVORITES, items.delete(sku).join(','))
  }
}

// * SLICE

const favoriteSlice = (set, get) => ({
  /**
   * immutable state
   */
  list: Set(),
  isLoading: true,
  /**
   * Initializes favorites from localStorate and backend service
   * @param {object} user
   */
  async initialize(user, loading) {
    set({ isLoading: true })
    if (loading) return
    const localFav = localStorage.getItem(RTG_FAVORITES)
    const localSet = Set(localFav ? localFav.split(',') : [])
    if (!user?.accessToken) {
      set({ list: localSet, isLoading: false })
      return
    }

    try {
      const data = await FavoritesService.api.get(user.accessToken)
      const accountFav = data?.products ? data?.products : []
      const serverSet = Set(accountFav)
      // unify both lists
      set({ list: Set.union([localSet, serverSet]) })
      // sync account with local
      const products = localSet.subtract(accountFav)
      if (!products.isEmpty()) {
        const body = { products: products.toArray(), append: true, remove: false }
        await FavoritesService.api.put({ token: user.accessToken, body })
      }
      // delete local fav
      localStorage.removeItem(RTG_FAVORITES)
      set({ isLoading: false })
    } catch (error) {
      // TODO notify user
      set({ list: localSet, isLoading: false })
    }
  },

  /**
   * Adds a new SKU to favorites
   * @param {object} user
   * @return {(sku: string) => void}
   */
  add: user => sku => {
    const {
      favorites: { list },
    } = get()
    if (user?.accessToken) {
      const mutation = FavoritesService.updateFavorites(user.accessToken)
      mutation({ values: { products: [sku], remove: false } }).catch(() => {
        // fallback
        // TODO notify user
        set({ list: list.delete(sku) })
      })
    } else {
      addToStorage(sku)
    }
    // optimistic update
    set({ list: list.add(sku) })
  },

  /**
   * Removes a given SKU from favorites
   * @param {object} user
   * @return {(sku: string) => void}
   */
  remove: user => sku => {
    const {
      favorites: { list },
    } = get()
    if (user?.accessToken) {
      const mutation = FavoritesService.updateFavorites(user.accessToken)
      mutation({ values: { products: [sku], remove: true } }).catch(() => {
        // fallback
        // TODO notify user
        set({ list: list.add(sku) })
      })
    } else {
      removeFromStorage(sku)
    }
    // optimistic update
    set({ list: list.delete(sku) })
  },
})

export default favoriteSlice
