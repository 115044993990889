import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { Collapse, List, ListItem as MuiListItem } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { ButtonItem, ListItemText } from '../Styled'

const SubItem = ({ data, onDrawerClose }) => {
  const [open, setOpen] = useState(false)

  const HeadingLink = data?.LinkList?.HeadingLink?.Link
  const Links = data?.LinkList?.Links

  const onLinkClick = e => {
    e.stopPropagation()
    onDrawerClose()
  }

  const onExpand = e => {
    e.stopPropagation()
    setOpen(!open)
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        category: 'navigation',
        action: 'open menu',
        label: HeadingLink?.DisplayText,
      })
    }
  }

  return (
    <>
      <ButtonItem sx={{ pl: 4 }} onClick={onExpand} divider>
        <ListItemText
          data-testid={HeadingLink?.id}
          data={HeadingLink}
          trackingData={{
            category: 'navigation',
            action: 'link click',
            label: HeadingLink?.DisplayText,
          }}
          onClick={onLinkClick}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ButtonItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          {Links?.map(item => {
            const link = item?.Link
            return (
              <MuiListItem key={link?.id} sx={{ pl: 6 }}>
                <ListItemText
                  data-testid={link?.id}
                  data={link}
                  trackingData={{
                    category: 'navigation',
                    action: 'link click',
                    label: link?.DisplayText,
                  }}
                  onClick={onLinkClick}
                />
              </MuiListItem>
            )
          })}
        </List>
      </Collapse>
    </>
  )
}

SubItem.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  data: PropTypes.object,
}

export default SubItem
