/* eslint-disable lines-between-class-members */
import Observer from './Observer'

/**
 * @typedef {Object} QueueNode
 * @property {string} queue_id
 * @property {object} payload
 */

/**
 * Immutable Queue
 * - first-in-first-out
 * - Array based
 * - can be used in Redux and useState
 * - implements observer pattern
 */
class ImmutableQueue {
  #elements
  #observer
  static LIFE_TIME = 4500

  constructor(elements = []) {
    this.#elements = elements
    this.#observer = new Observer()
  }

  /**
   * Array with all elements in the queue
   * @returns {QueueNode[]}
   */
  get list() {
    return this.#elements
  }

  /** First element at the top of the queue */
  get first() {
    const last = this.#elements[0]
    return last
  }

  /** Last element leaving the queue */
  get last() {
    return this.#elements.slice(-1)[0]
  }

  /** Returns unique random identifier */
  #generateId() {
    return `${Math.floor(Math.random() * 1000) + 1000}-${Date.now()}`
  }

  /** Removes all items on the queue */
  reset(elements = []) {
    this.#elements = elements
    this.#observer.emit(this)
  }

  /**
   * Adds a new node to the end of the Queue
   * @param {object} payload
   * @returns {string} queue id of the added element
   */
  enqueue(payload) {
    if (!payload) {
      throw new Error('No queue payload has been provided')
    }
    const queue_id = this.#generateId()
    this.#elements = [...this.#elements, { queue_id, payload }]
    this.#observer.emit(this)
    if (!payload.persist) {
      setTimeout(() => this.removeById(queue_id), ImmutableQueue.LIFE_TIME)
    }
    return queue_id
  }

  /** Removes the first item in the Queue */
  dequeue() {
    this.#elements = this.#elements.slice(1)
    this.#observer.emit(this)
  }

  /**
   * @param {string} queue_id
   * @returns {QueueNode}
   */
  findById(queue_id) {
    return this.#elements.find(item => item.queue_id === queue_id)
  }
  /**
   * @param {string} queue_id
   * @returns {void}
   */
  removeById(queue_id) {
    this.#elements = this.#elements.filter(item => item.queue_id !== queue_id)
    this.#observer.emit(this)
  }

  /**
   * Subscribes to changes in the queue
   * @param {(queue: ImmutableQueue) => void} callback
   * @returns {() => void} unsubscribe function
   */
  subscribe(callback) {
    return this.#observer.subscribe(callback)
  }
}

export default ImmutableQueue
