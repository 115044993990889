import React, { useState, useCallback, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import modalImg from '../../assets/images/acct-img.png'

const getImageHeight = ref => ((ref?.current?.offsetHeight || 462) - 40) / 2

const ModalImage = () => {
  const ref = useRef(null)
  const [y, setY] = useState(-50)

  useEffect(() => {
    setY(getImageHeight(ref) * -1)
  }, [])

  return (
    <motion.img
      ref={ref}
      src={modalImg}
      alt="Modal"
      transition={{ type: 'spring' }}
      initial={{ opacity: 0, y }}
      animate={{
        opacity: 1,
        y,
      }}
    />
  )
}

export default ModalImage
