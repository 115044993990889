import React, { createContext, useContext, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modals from '@components/modals'
import { getURLParam } from '@helpers/general'
import { reducer, actions } from './reducer'

/**
 * @typedef {import('./reducer').ModalActions} ModalActions
 */

/**
 * @typedef {import('./reducer').ModalState} ModalState
 */

/**
 * @typedef {Object} ModalsContext
 * @property {ModalActions} actions Available actions in the accounts context
 * @property {Function} dispatch triggers actions
 * @property {ModalState} modal state
 */

/**
 * Account Context
 * @type {ModalsContext}
 * */
const initialContextValue = {
  actions,
  modal: {},
  dispatch: () => {},
}

const ModalsContext = createContext(initialContextValue)

export const ModalsContextProvider = ({ children }) => {
  const [modal, dispatch] = useReducer(reducer, {})

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.search) {
      const queryMode = getURLParam('mode')
      const resetPasswordToken = getURLParam('oobCode')
      if (queryMode === 'resetPassword' && resetPasswordToken) {
        dispatch({ type: actions.resetPassword })
      } else if (queryMode === 'login') {
        dispatch({ type: actions.login })
      }
    }
  }, [])

  /**
   * Account Context
   * @type {ModalsContext}
   * */
  const context = {
    actions,
    modal,
    dispatch,
  }

  return (
    <ModalsContext.Provider value={context}>
      <Modals />
      {children}
    </ModalsContext.Provider>
  )
}

ModalsContextProvider.propTypes = {
  children: PropTypes.node,
}

/**
 * @returns {ModalsContext}
 */
export const useModalsContext = () => useContext(ModalsContext)
