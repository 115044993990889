import React from 'react'
import { makeStyles, Typography, Box } from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)',
  },
}))

const Subtitle = ({ text }) => {
  const classes = useStyles()
  return (
    <Box className={classes.titleWrapper}>
      <Typography variant="h6">{text}</Typography>
    </Box>
  )
}

Subtitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Subtitle
