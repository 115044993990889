import React, { useState, useEffect } from 'react'

import { AppBar, Toolbar, useMediaQuery, useTheme, Collapse } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

import RTGLogo from '@shared/svgs/logo'
import SearchForm from '@components/shared/search-form'
import { LeftSideBtn, LogoWrapper } from '@components/header/mobile/Styled'
import StoreLocator from '@components/header/mobile/AppBar/StoreLocator'
import Delivery from '@components/header/mobile/AppBar/Delivery'
import Cart from '@components/header/mobile/AppBar/Cart'
import MobileDrawer from '@components/header/mobile/Drawer'

const MobileAppBar = () => {
  const [drawer, setDrawer] = useState(false)
  const [showSearch, setShowSearch] = useState(true)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const onDrawerClose = () => setDrawer(false)
  const onDrawerClick = () => setDrawer(true)

  useEffect(() => {
    if (drawer) {
      setShowSearch(false)
    } else {
      setTimeout(() => {
        setShowSearch(true)
      }, theme.transitions.duration.leavingScreen)
    }
  }, [drawer, theme.transitions.duration.leavingScreen])

  // Removes Mobile Hedear on browser runtime (when is desktop) but renders it at build time
  if (typeof window !== 'undefined' && !isMobile) {
    return null
  }

  return (
    <AppBar position="fixed" className="mobile-only" sx={{ zIndex: t => t.zIndex.drawer + 1 }}>
      <Toolbar>
        <LeftSideBtn size="large" edge="start" color="primary" aria-label={drawer ? 'close menu' : 'open menu'}>
          {drawer ? <CloseIcon onClick={onDrawerClose} /> : <MenuIcon onClick={onDrawerClick} />}
        </LeftSideBtn>
        <StoreLocator />
        <LogoWrapper
          data={{
            InternalUrl: '/',
            Title: 'Rooms To Go Home',
          }}
          trackingData={{ category: 'header', action: 'logo click', label: 'logo' }}
        >
          <RTGLogo uniqueNameForId="mobile-header" />
        </LogoWrapper>
        <Delivery />
        <Cart />
      </Toolbar>
      {!drawer && (
        <Collapse in={showSearch} unmountOnExit>
          <Toolbar>
            <SearchForm
              viewName="mobile-header"
              id="search-mobile cell"
              addClasses={{
                searchmobile: true,
                'mobile-only': true,
              }}
              mobileHitsCount
            />
          </Toolbar>
        </Collapse>
      )}
      <MobileDrawer open={drawer} onDrawerClose={onDrawerClose} />
    </AppBar>
  )
}

export default MobileAppBar
