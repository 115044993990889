import { ListItemButton, IconButton, styled } from '@mui/material'
import StrapiLink from '@templates/strapi-cms/content-types/Link'

const HeaderIcoonBtn = styled(IconButton)`
  svg {
    font-size: 1.7rem;
  }
  &,
  &:focus {
    outline: none !important;
  }
`

export const LeftSideBtn = styled(HeaderIcoonBtn)`
  margin-right: ${p => p.theme.spacing(1)};
`

export const RightSideBtn = styled(HeaderIcoonBtn)`
  margin-left: ${p => p.theme.spacing(2)};
`

export const LogoWrapper = styled(StrapiLink)`
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  svg {
    max-width: 250px;
    width: 100%;
  }
`
export const ButtonItem = styled(ListItemButton)`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

export const ListItemText = styled(StrapiLink)`
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  color: ${p => p.theme.palette.text.primary};
  min-width: 50%;

  &:focus {
    text-decoration: underline;
    color: ${p => p.theme.palette.primary.main};
  }
`

export const FooterLink = styled(StrapiLink)`
  display: flex;
  width: 100%;

  .material-icons-outlined {
    font-size: 2.2rem;
    color: ${p => p.theme.palette.text.primary};
  }

  span.text {
    display: block;
    color: ${p => p.theme.palette.text.primary};
    padding-left: 8px;

    span {
      display: block;
      font-weight: 400;

      &.title {
        font-weight: 600;
      }
    }
  }

  &:focus {
    span,
    .material-icons-outlined {
      color: ${p => p.theme.palette.primary.main};
    }
  }
`
