const hasSearchQuery = content => {
  let containsSearch = false
  if (content) {
    content.forEach(section => {
      containsSearch = section.__typename === 'ContentfulSearchQuery' ? true : containsSearch
    })
  }
  return containsSearch
}

exports.hasSearchQuery = hasSearchQuery

const validPublishDate = content => {
  if (content && content.startDate) {
    const startTime = new Date(content.startDate).getTime()
    const endTime = new Date(content.endDate).getTime()
    const nowTime = Date.now()
    if (nowTime > endTime) {
      return false
    }
    return nowTime > startTime
  }
  return true
}
exports.validPublishDate = validPublishDate

exports.canPublishContent = (content, disabledCoupons) => {
  if (content.__typename === 'ContentfulCoupon') {
    if (disabledCoupons && disabledCoupons.some(couponId => content.contentful_id === couponId)) {
      return false
    }
  }
  if (Array.isArray(content)) {
    for (let i = 0; i < content.length; i++) {
      if (validPublishDate(content[i])) {
        return true
      }
    }
    return false
  }
  return validPublishDate(content)
}

exports.selectRegionBasedContent = (region, allPageContent) => {
  if (allPageContent) {
    if (region) {
      if (region === 'SE' && allPageContent.contentSe) {
        return allPageContent.contentSe
      }
      if (region === 'FL' && allPageContent.contentFl) {
        return allPageContent.contentFl
      }
      if (region === 'TX' && allPageContent.contentTx) {
        return allPageContent.contentTx
      }
      if (region === 'OOM' && allPageContent.contentOom) {
        return allPageContent.contentOom
      }
    }
    return allPageContent.contentDefault
  }
  return null
}

const contentfulImage = url => {
  const params = url.split('?')
  const path = params[0].split('/')
  // The rtg image api uses f=webp or fm=webp, Safari only support fm=webp
  const customParams = params[1] ? params[1].replace(/f=webp/, 'fm=webp') : ''
  return `${process.env.GATSBY_S3_IMAGE_URL}${path[path.length - 1]}?cache-id=${path[path.length - 2]}${customParams}`
}

exports.contentfulImage = contentfulImage

/**
 *
 * @param {object} contentfulImageData
 * @return {object} Image data with rtg urls
 * @description Accepts contentful image data, and returns image data with urls that map to rtg domains
 */
exports.getRTGImageData = contentfulImageData => {
  if (!contentfulImageData) return null
  const rtgImageData = {}
  Object.keys(contentfulImageData).forEach(key => {
    const entry = contentfulImageData[key]
    if (typeof entry === 'string' && entry.includes('ctfassets')) {
      if (entry.includes(',')) {
        const urls = entry.split(',')
        const rtgURLs = urls.map(url => contentfulImage(url))
        rtgImageData[key] = rtgURLs.join(',')
      } else {
        rtgImageData[key] = contentfulImage(entry)
      }
    }
  })

  return { ...contentfulImageData, ...rtgImageData }
}
