class Observer {
  #listeners

  constructor() {
    this.#listeners = []
  }

  subscribe(callback) {
    if (typeof callback === 'function') {
      this.#listeners.push(callback)
    }
    return () => this.unsubscribe(callback)
  }

  unsubscribe(callback) {
    this.#listeners = this.#listeners.filter(item => item !== callback)
  }

  emit(value = null) {
    this.#listeners.forEach(callback => callback(value))
  }
}

export default Observer
