import React, { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { Grid, Button, Typography, IconButton, Link as MuiLink } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import NavigateNext from '@mui/icons-material/NavigateNext'

import { getCartTotal, getStoreCartTotal, removeFromCart } from '@helpers/cart'
import { currencyFormatUS } from '@helpers/string-helper'
import { isProductStrikeThrough, productPrice } from '@helpers/product'
import { productUrl } from '@helpers/route'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'

export const getProperlySizedImageUrl = url => {
  if (url) {
    const searchParam = 'h=50'
    const seperator = url.includes('?') ? '&' : '?'
    return `${url}${seperator}${searchParam}`
  }
  return ''
}

export const CartPopout = () => {
  const { cart } = useSelector(state => state.cart)
  const { order } = useSelector(state => state.checkout)

  const cartTotal = useMemo(() => {
    let total = getCartTotal(cart)
    if (order && order.lineItems) {
      const storeCartLineItems = order.lineItems.filter(lineItem => lineItem.isStoreSku)
      if (storeCartLineItems.length > 0) {
        total += getStoreCartTotal(storeCartLineItems)
      }
    }
    return total
  }, [cart, order])

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Typography id="cartSubtotal" color="error" variant="subtitle1" textAlign="center">
          Subtotal: <b>{currencyFormatUS(cartTotal)}</b>
        </Typography>
      </Grid>

      <Grid item sm={12}>
        <Button
          component={ButtonLink}
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          data={{
            InternalUrl: '/checkout',
            Title: 'Go to Checkout',
          }}
        >
          Checkout
        </Button>
      </Grid>
      <Grid item sm={12}>
        <Button
          size="small"
          component={ButtonLink}
          data={{
            InternalUrl: '/cart',
            Title: 'View Full Cart',
            DisplayText: 'View Full Cart',
          }}
          fullWidth
          trackingData={{ category: 'header', action: 'cart interaction', label: 'view full cart' }}
          endIcon={<NavigateNext />}
        >
          View Full Cart
        </Button>
      </Grid>
      {(!cart || (cart?.cartItems && cart.cartItems.length === 0)) && (
        <Grid item sm={12}>
          <Typography textAlign="center" variant="body2">
            Your shopping cart is empty
          </Typography>
        </Grid>
      )}
      {cart?.cartItems &&
        cart.cartItems.length > 0 &&
        cart.cartItems.map((item, index) => {
          const { product } = item
          let route = null
          if (product && product.route) route = product.route
          const strikethrough = isProductStrikeThrough(product)
          return (
            <Grid container item sm={12} key={item.sku} spacing={1}>
              <hr />
              <Grid container item sm={11} spacing={1}>
                <Grid item sm={4}>
                  <MuiLink
                    component={ButtonLink}
                    data={{
                      InternalUrl: productUrl(route),
                    }}
                    aria-hidden="true"
                    sx={t => ({
                      display: 'flex',
                      justifyContent: 'center',
                      border: 'solid 2px transparent',
                      '&:focus': {
                        border: `solid 2px ${t.palette.primary.main}`,
                      },
                    })}
                    disabled={product.category === 'gift-card'}
                  >
                    <img
                      style={{
                        height: '60px',
                        width: 'auto',
                      }}
                      src={getProperlySizedImageUrl(product.primary_image)}
                      alt={product.title}
                      role="presentation"
                    />
                  </MuiLink>
                </Grid>

                <Grid container item sm={8}>
                  <Grid item sm={12}>
                    <MuiLink
                      component={ButtonLink}
                      data={{
                        InternalUrl: productUrl(route),
                      }}
                      sx={{
                        fontSize: '0.9rem',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                        '&:focus': {
                          textDecoration: 'underline',
                        },
                      }}
                      aria-describedby={`mcq_${index}mcp_${index}`}
                      disabled={product.category === 'gift-card'}
                    >
                      {product.title}
                    </MuiLink>
                  </Grid>

                  <Grid item sm={6}>
                    <Typography sx={{ fontSize: '0.8rem' }} variant="body2">
                      Qty: {item.quantity}
                    </Typography>
                  </Grid>

                  <Grid item sm={6}>
                    {strikethrough && (
                      <Typography variant="body2" sx={{ fontSize: '0.8rem' }} className="strikethrough">
                        {currencyFormatUS(productPrice(product, true))}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={{ fontSize: '0.8rem' }}
                      className={strikethrough ? 'strikethrough-sale' : ''}
                    >
                      {currencyFormatUS(productPrice(product))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={1}>
                <IconButton title="Remove from cart" size="small" onClick={() => removeFromCart(product, index)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          )
        })}
    </Grid>
  )
}

export default CartPopout
