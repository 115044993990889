/**
 *
 * Documentation:
 *
 * - [App bar](https://mui.com/material-ui/react-app-bar/)
 * - [Menus](https://mui.com/material-ui/react-menu/)
 *
 * API:
 *
 * - [Menu API](https://mui.com/material-ui/api/menu/)
 * - inherits [Popover API](https://mui.com/material-ui/api/popover/)
 */

const MuiMenu = {
  defaultProps: {
    elevation: 0,
    PaperProps: {
      sx: {
        border: '1px solid grey',
        borderRadius: 0,
        p: 0,
        '.MuiMenu-list': {
          p: 0,
        },
      },
    },
  },
}

export default MuiMenu
