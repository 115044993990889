import React from 'react'

import { useModalsContext } from '@context/modalContext'
import { useNotifications } from '@context/notifications'
import { useLogOut } from '@hooks/auth'
import { ButtonLarge, Wrapper } from './styled'

export const MODAL_ID = 'MODAL/LOGOUT'

export const Logout = () => {
  const { dispatch, actions } = useModalsContext()
  const { enqueue } = useNotifications()
  const logOut = useLogOut()

  const closeModal = () => dispatch({ type: actions.close })

  const handleLogOut = () => {
    logOut()
    closeModal()
    enqueue({ variant: 'filled', body: 'You’ve logged out successfully.' })
  }

  return (
    <Wrapper>
      <ButtonLarge onClick={handleLogOut} fullWidth size="large" variant="contained" color="primary">
        YES
      </ButtonLarge>
      <ButtonLarge onClick={closeModal} fullWidth size="large" variant="outlined" color="primary">
        NO
      </ButtonLarge>
    </Wrapper>
  )
}
