import create from 'zustand'
import { devtools } from 'zustand/middleware'
import favoriteSlice from './favorites'

const sliceFactory = (setState, getState) => (slice, name) => {
  const set = partial => {
    if (typeof partial === 'function') {
      setState(state => ({ [name]: { ...state[name], ...partial(state[name]) } }))
      return
    }
    if (typeof partial === 'object') {
      setState(state => ({ [name]: { ...state[name], ...partial } }))
      return
    }
    throw new Error('Invalid partial state has been provided...')
  }
  return { [name]: slice(set, getState) }
}

const useStore = create(
  devtools(
    (set, get) => {
      const createSlice = sliceFactory(set, get)
      return {
        ...createSlice(favoriteSlice, 'favorites'),
        ...createSlice(() => ({}), 'cart'),
        ...createSlice(() => ({}), 'checkout'),
      }
    },
    { name: 'Zustand' },
  ),
)

export default useStore
