// dependencies
import React from 'react'
import {
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Box,
  CircularProgress,
  styled,
} from '@mui/material'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
// contexts
import { useModalsContext } from '@context/modalContext'
// hooks
import { useLoginForm } from './hooks'
// components
import { RequiredFieldsMessage } from '../Common'

const handleMouseDownPassword = event => {
  event.preventDefault()
}

const StyledForm = styled(Grid)(() => ({
  paddingTop: '16px',
  '& > *': {
    padding: '10px',
  },
  '& input[type="text"], & input[type="password"]': {
    width: '100%',
    margin: 0,
    border: 'none !important',
    padding: '10.5px 14px !important',
    boxSizing: 'content-box',
  },
  '& button': {
    minWidth: 'unset !important',
  },
}))

const StyledActionsContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: '0 !important',
}))

const StyledCreateAnAccountText = styled(Typography)(() => ({
  marginTop: '1.2rem',
  '& > button': {
    marginLeft: '6px',
  },
}))

const StyledLinkButton = styled(Button)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main,
  textTransform: 'unset',
  '&:hover': {
    color: theme.palette.primary.dark,
  },
  minWidth: 'unset',
  padding: 0,
  '& > span': {
    justifyContent: 'flex-start',
  },
}))

const StyledButton = styled(Button)(() => ({
  minWidth: '12rem',
  fontWeight: 600,
  marginBottom: '-15px',
}))

const NoPaddingGrid = styled(Grid)`
  padding-right: 0 !important;
`

export const MODAL_ID = 'MODAL/LOGIN'

export const Login = () => {
  const { dispatch, actions } = useModalsContext()

  const { formik, showPassword, onVisibilityToggle, loading, errorMessage } = useLoginForm()

  return (
    <Box>
      <RequiredFieldsMessage />
      <StyledForm spacing={2} component="form" container onSubmit={formik.handleSubmit}>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="email"
            autoComplete="email"
            name="email"
            label="Email"
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="password"
            autoComplete="password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            required
            value={formik.values.password}
            onChange={formik.handleChange}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onVisibilityToggle('password')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </NoPaddingGrid>
        {errorMessage && (
          <NoPaddingGrid item xs={12} sm={12} md={12}>
            <Typography color="error" style={{ fontSize: '14px' }}>
              {errorMessage}
            </Typography>
          </NoPaddingGrid>
        )}
        <StyledActionsContainer item xs={12} sm={12} md={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <StyledButton
              disabled={!formik.values.password || !formik.values.email}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              type="submit"
            >
              LOG IN
            </StyledButton>
          )}
        </StyledActionsContainer>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <StyledLinkButton variant="text" onClick={() => dispatch({ type: actions.forgotPassword })}>
            Forgot Password?
          </StyledLinkButton>
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <StyledCreateAnAccountText>
            Need to
            <StyledLinkButton variant="text" onClick={() => dispatch({ type: actions.createAccount })}>
              Create an Account?
            </StyledLinkButton>
          </StyledCreateAnAccountText>
        </NoPaddingGrid>
      </StyledForm>
    </Box>
  )
}
