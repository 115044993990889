/**
 *
 * Documentation:
 *
 * - [App bar](https://mui.com/material-ui/react-app-bar/)
 *
 * API:
 *
 * - [AppBar API](https://mui.com/material-ui/api/app-bar/)
 * - inherits [Paper API](https://mui.com/material-ui/api/paper/)
 */

import palette from '../palette'

const MuiAppBar = {
  defaultProps: {
    position: 'sticky',
    variant: 'outlined',
    elevation: 0,
  },
  styleOverrides: {
    zIndex: 1300,
    colorPrimary: {
      backgroundColor: palette.common.white,
    },
  },
}

export default MuiAppBar
