import React from 'react'

import PropTypes from 'prop-types'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import { Grid, styled, Icon, Typography } from '@mui/material'

const FooterLink = styled(StrapiLink)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .material-icons-outlined {
    font-size: 2.2rem;
    color: ${p => p.theme.palette.text.primary};
  }

  span.text {
    display: block;
    color: ${p => p.theme.palette.text.primary};
    padding-left: 8px;

    span {
      display: block;
      font-weight: 400;

      &.title {
        font-weight: 600;
      }
    }
  }

  &:focus {
    outline: none !important;
    span {
      color: ${p => p.theme.palette.primary.main};
      text-decoration: underline;
    }
  }

  &:hover {
    span,
    .material-icons-outlined {
      color: ${p => p.theme.palette.primary.main};
    }
  }
`

const Wrapper = styled(Grid)`
  padding-bottom: ${p => p.theme.spacing(2)};
  padding-left: 0 !important;
  padding-right: ${p => p.theme.spacing(2)};
  margin-bottom: -${p => p.theme.spacing(2)};
  margin-top: ${p => p.theme.spacing(2)};
  background-color: #f2f2f2;
`

const Section = styled(Grid)`
  &:not(:last-child) {
    border-right: solid 1px #4b4b4b;
  }
`

const NavigationFooter = ({ data = null, onClose }) => {
  if (!data || data?.length === 0) return null
  return (
    <Wrapper item container sm={12}>
      {data.slice(0, 4).map(item => {
        const { Text, Caption, MaterialIcon = 'flag' } = item?.BannerButton || {}
        const { id = null, InternalUrl = '/' } = item?.BannerButton?.Link?.Link || {}
        return (
          <Section key={`nav-footer-${id}}`} item sm>
            <FooterLink
              data={{ InternalUrl, id, Title: Text }}
              onClick={onClose}
              trackingData={{
                category: 'navigation footer',
                action: 'link click',
                label: Text,
              }}
            >
              <Icon baseClassName="material-icons-outlined" fontSize="2rem">
                {MaterialIcon}
              </Icon>
              <span className="text">
                <Typography className="title" as="span">
                  {Text}
                </Typography>
                <Typography className="caption" variant="caption" as="span">
                  {Caption}
                </Typography>
              </span>
            </FooterLink>
          </Section>
        )
      })}
    </Wrapper>
  )
}

NavigationFooter.propTypes = {
  data: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default NavigationFooter
