/**
 * Refer to the [Icons](https://mui.com/material-ui/icons/) section of the documentation
 * regarding the available icon options.
 *
 * Demos:
 *
 * - [Buttons](https://mui.com/material-ui/react-button/)
 *
 * API:
 *
 * - [IconButton API](https://mui.com/material-ui/api/icon-button/)
 * - inherits [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */

import palette from '../palette'

const MuiIconButton = {
  styleOverrides: {
    colorPrimary: {
      color: palette.primary.dark,
    },
  },
}

export default MuiIconButton
