import React, { useState, useRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField, Grid, Button, styled } from '@mui/material'
import { addToDataLayer, suggestionMissEvent, suggestionPassEvent } from '@helpers/google-tag-manager'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import { navigate } from 'gatsby'
import qs from 'qs'
import algoliasearch from 'algoliasearch/lite'
import { useTheme } from '@mui/material/styles'
import ClickOutsideWrapper from '@shared/clickOutsideWrapper'
import { getRegionZone } from '@helpers/geo-location'
import { useLocation } from '@gatsbyjs/reach-router'
import { theme } from '@rtgdev/design-system'
import { canClientSideRoute } from '@helpers/link'

import Loader from '../generic/loader'
import { ConnectedSuggestionList } from './connectors/ConnectedSuggestionList'

import '../../assets/css/components/search/connectors/search-box.sass'

const DEFAULT_SEARCH_BOX_DEBOUNCE_TIME = 300
const algoliaClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY)
const regionInPR = getRegionZone().region === 'PR'

const StyledFormSearch = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

const StyledInputSearch = styled(TextField)(() => ({
  '& > label': {
    color: 'fieldtext',
  },
  '& > div': {
    height: '100%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '& > input': {
      fontSize: '1rem',
      padding: '8px 14px !important',
    },
    '& > fieldset': {
      borderColor: 'gray',
    },
  },
}))

const StyledClearButton = styled(Button)(() => ({
  position: 'absolute !important',
  fontSize: '14px',
  borderRadius: '15px',
  padding: '0.25em 0.35em',
  top: 0,
  bottom: 0,
  margin: 'auto 0',
  height: '50%',
  right: '55px !important',
  minWidth: 'unset',
  '& > svg': {
    height: '10px',
    width: '10px',
    fill: `white !important`,
    zIndex: 1,
  },
  '& > span:last-child': {
    background: `gray !important`,
  },
  [theme.breakpoints.down('md')]: {
    padding: '6px',
  },
}))

const getQueryInURL = ({ search }) => {
  let query = ''
  if (search) {
    query = qs.parse(search.slice(1)).query ?? ''
  }
  return query
}

const SearchBox = ({ mobileHitsCount = false, delay = DEFAULT_SEARCH_BOX_DEBOUNCE_TIME, viewName }) => {
  const suggestionRef = useRef(null)
  const searchBoxRef = useRef(null)
  const location = useLocation()
  const [query, setQuery] = useState(getQueryInURL(location))
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [renderSuggestions, setRenderSuggestions] = useState(false)
  const [searching, setSearching] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState(getQueryInURL(location))
  const [timerId, setTimerId] = useState(null)
  let innerWidth = 0
  if (typeof window !== 'undefined') {
    innerWidth = window.innerWidth
  }
  const [width, setWidth] = useState(innerWidth)
  const isMobile = width <= theme.breakpoints.values.sm

  const onInputChange = value => {
    if (!showSuggestions && value.length > 2) {
      setShowSuggestions(true)
    }

    setQuery(value)
  }

  const onChangeDebounced = event => {
    event.preventDefault()
    clearTimeout(timerId)

    const { value } = event.currentTarget

    setSearchInputValue(value)
    const newtimerId = setTimeout(() => onInputChange(value), delay)

    setTimerId(newtimerId)
  }

  const onInputBlur = () => {
    addToDataLayer('blur', 'site search', 'blur', query)
  }

  const handleWindowSizeChange = () => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
    return null
  })

  const onInputKeyDown = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      const { value } = e.currentTarget
      e.target.blur()
      onFormSubmit(value)
    }
    if (e.keyCode === 40) {
      suggestionRef.current.focus()
    }
  }

  const onFormSubmit = (e, suggested = false, suggestion, index) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    let searchValue = query
    if (typeof e === 'string') {
      searchValue = e
    }

    if (query?.length === 0) {
      return null
    }

    if (!suggested) {
      addToDataLayer('submit', 'site search', 'submit', query)
      suggestionPassEvent({ query: searchValue, suggested: false })
    } else {
      suggestionPassEvent({ query: searchValue, suggested: true, suggestion, index })
      setSearchInputValue(suggestion)
      setQuery(suggestion)
    }

    setSearching(true)
    setTimeout(() => setSearching(false), 1000)
    let searchUrl = `/search/?query=${suggested ? suggestion : searchValue}&filters=true`
    if (canClientSideRoute(location.pathname, searchUrl)) {
      navigate(searchUrl)
    } else {
      // Full navigations will redirect if there is a trailing slash
      searchUrl = `/search?query=${suggested ? suggestion : searchValue}&filters=true`
      window.location.href = searchUrl
    }

    return setShowSuggestions(false)
  }

  const clearInputValue = () => {
    suggestionMissEvent(query)
    setSearchInputValue('')
    setQuery('')
  }

  const onFocus = useCallback(() => {
    setRenderSuggestions(true)
  }, [])

  return (
    <ClickOutsideWrapper
      callback={setShowSuggestions}
      analyticsCB={showSuggestions ? suggestionMissEvent : null}
      analyticsData={query}
      toggle={query?.length > 2}
    >
      <div className="ais-SearchBox">
        <StyledFormSearch>
          <StyledInputSearch
            tabIndex={0}
            size="small"
            fullWidth
            variant="outlined"
            data-testid={`${isMobile ? 'search-mobile' : 'search-desktop'}-${viewName}`}
            name="search"
            label="Find your furniture"
            value={searchInputValue}
            onChange={onChangeDebounced}
            onFocus={onFocus}
            onBlur={onInputBlur}
            onKeyDown={onInputKeyDown}
            ref={searchBoxRef}
            autoComplete="off"
          />
          {!regionInPR && renderSuggestions && (
            <InstantSearch
              searchClient={algoliaClient}
              indexName={`${getRegionZone().region.toLowerCase()}-suggestions`}
            >
              <Configure hitsPerPage={mobileHitsCount ? 7 : 10} query={query} />
              <ConnectedSuggestionList
                query={query}
                onFormSubmit={onFormSubmit}
                showSuggestions={showSuggestions}
                tabIndex={0}
                suggestionRef={suggestionRef}
                searchBoxRef={searchBoxRef}
                mobileHitsCount={mobileHitsCount}
              />
            </InstantSearch>
          )}
          {query && (
            <StyledClearButton
              type="button"
              title="Clear the search query."
              hidden=""
              onClick={clearInputValue}
              tabIndex={0}
            >
              <svg
                className="ais-SearchBox-resetIcon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                width="10"
                height="10"
                alt=""
                aria-hidden="true"
                role="presentation"
              >
                <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z" />
              </svg>
            </StyledClearButton>
          )}
          <button
            data-testid={`${isMobile ? 'searchButton-mobile' : 'searchButton-desktop'}-${viewName}`}
            type="submit"
            title="Submit the search query."
            className="ais-SearchBox-submit"
            onClick={onFormSubmit}
            hidden=""
          >
            {query && searching ? (
              <Loader />
            ) : (
              <svg
                className="ais-SearchBox-submitIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 40 40"
                aria-hidden="true"
                role="presentation"
                focusable="false"
              >
                <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z" />
              </svg>
            )}
            <span className="hide508">Submit the search query.</span>
          </button>
        </StyledFormSearch>
      </div>
    </ClickOutsideWrapper>
  )
}

SearchBox.propTypes = {
  delay: PropTypes.any,
  mobileHitsCount: PropTypes.bool,
  viewName: PropTypes.string,
}

export default SearchBox
