import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  Typography,
  Hidden,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Box,
  IconButton,
  useMediaQuery,
} from '@mui/material'
import { useInView } from 'react-intersection-observer'
import { ChevronRight, ChevronLeft } from '@mui/icons-material'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper/core'

// Component Styles
import { PRODUCT_TILE, PRODUCT_CAROUSEL } from './ProductCarousel.styles'

// Dependencies
import QuickViewModal from '../QuickViewModal/QuickViewModal'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/a11y/a11y.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay, A11y])

export default function ProductCarousel({
  cartTotal,
  cartItems,
  id,
  slides,
  title,
  price_zone,
  addToCart,
  trigger_gtm,
}) {
  const [activeProduct, setActiveProduct] = useState(null)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const openModal = (product, reason) => {
    // [GTM] - Track when user opens modal
    trigger_gtm('quickView_modal--open', {
      reason,
      sku: product.sku,
      title: product.title,
    })
    return setActiveProduct(product)
  }
  const closeModal = (product, reason) => {
    // [GTM] - Track when user closes modal and specify the reason
    trigger_gtm('quickView_modal--close', {
      reason,
      sku: activeProduct.sku,
    })
    return setActiveProduct(null)
  }
  const addProduct = (product, reason) => {
    const product_price = product.pricing[price_zone]
    // [GTM] - Track when user adds an item to cart
    trigger_gtm('ymal_carousel--addToCart', {
      reason,
      sku: product.sku,
      title: product.title,
    })
    return addToCart(product, product_price, [], false, product.quantity)
  }
  const [ref, inView] = useInView({
    threshold: 0,
  })
  useEffect(
    () =>
      // [GTM] - Track impressions of You May Also Like Carousel
      trigger_gtm('ymal_carousel', {
        page: 'cart',
        scrollable:
          document.querySelector('.swiper-scrollbar') &&
          window.getComputedStyle(document.querySelector('.swiper-scrollbar')).display !== 'none',
        inView,
        total_products: slides?.length,
        suggested_products: slides.map(product => product.sku),
        cart_total: cartTotal,
        cart_items: cartItems,
      }),
    [cartItems, cartTotal, inView, slides, trigger_gtm],
  )
  return (
    <Stack id={id} width="100%" my={3} position="relative">
      <Typography ref={ref} variant="subtitle1" mb={3}>
        {title}
      </Typography>
      <PRODUCT_CAROUSEL className="product-carousel">
        <Box className="swiper-button-prev">
          <Hidden mdDown>
            <IconButton aria-label="previous product">
              <ChevronLeft />
            </IconButton>
          </Hidden>
        </Box>
        <Box className="swiper-button-next">
          <Hidden mdDown>
            <IconButton className="swiper-button-next" aria-label="next product">
              <ChevronRight />
            </IconButton>
          </Hidden>
        </Box>
        <Swiper
          scrollbar={{
            hide: false,
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          breakpoints={{
            '320': {
              slidesPerView: 1.5,
              spaceBetween: 0,
            },
            '768': {
              slidesPerView: 2.5,
              spaceBetween: 0,
            },
            '900': {
              slidesPerView: 'auto',
              spaceBetween: 8,
            },
          }}
          centeredSlides={isMobile}
          freeMode={!isMobile}
        >
          {slides.map((product, index) => {
            const { pricing } = product
            const list_price = pricing[`${price_zone}_list_price`]
            const sale_price = pricing[`${price_zone}_sale_price`]

            return (
              <SwiperSlide className={`swiper-slide-${index}`} key={product.sku}>
                <PRODUCT_TILE elevation={isMobile ? 3 : 0}>
                  {/* [GTM] - Tracks when user clicks on ProductTile to open the Quickview Modal */}
                  <CardActionArea disableRipple disableTouchRipple onClick={() => openModal(product, 'productTile')}>
                    <CardMedia
                      component="img"
                      height={isMobile ? 130 : 140}
                      image={product.primary_image}
                      alt={product.title}
                    />
                    {/* [GTM] - Tracks when user clicks {product.title} to open the Quickview Modal */}
                    <CardContent>
                      <Typography
                        component="p"
                        variant={isMobile ? 'body1' : 'caption'}
                        gutterBottom={!isMobile}
                        fontWeight={isMobile ? 300 : 400}
                        lineHeight={isMobile ? 'auto' : '19px'}
                        onClick={() => openModal(product, 'productTitle')}
                      >
                        {product.title}
                      </Typography>
                      {/* [GTM] - Tracks when user clicks on {product.price} to open the Quickview Modal */}
                      <Typography component="p" variant="body2" onClick={() => openModal(product, 'productPrice')}>
                        ${list_price > sale_price ? sale_price : list_price}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    {/* [GTM] - Tracks when user clicks on {Add To Cart} button from ProductTile */}
                    <Button
                      fullWidth
                      size="small"
                      disableElevation
                      color="primary"
                      variant={isMobile ? 'contained' : 'text'}
                      onClick={() => addProduct(product, 'carousel_cta')}
                    >
                      Add To Cart
                    </Button>
                  </CardActions>
                </PRODUCT_TILE>
              </SwiperSlide>
            )
          })}
        </Swiper>
        {activeProduct !== null && (
          <QuickViewModal
            addToCart={product => addProduct(product, 'modal_cta')}
            isOpen={activeProduct}
            closeModal={reason => closeModal(reason)}
            product_json={activeProduct}
            price_zone={price_zone}
            isMobile={isMobile}
          />
        )}
      </PRODUCT_CAROUSEL>
    </Stack>
  )
}

ProductCarousel.propTypes = {
  cartItems: PropTypes.array,
  cartTotal: PropTypes.any,
  trigger_gtm: PropTypes.func,
  id: PropTypes.string,
  slides: PropTypes.array,
  title: PropTypes.string,
  price_zone: PropTypes.string,
  addToCart: PropTypes.func,
}
