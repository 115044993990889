import { useCallback } from 'react'
import { useFormik } from 'formik'
import { useCreateUserMutation, useAuthErrorMessage } from '@hooks/auth'
import usePasswordVisibility from '@hooks/usePasswordVisibility'
import { useModalsContext } from '@context/modalContext'
import { useNotifications } from '@context/notifications'
import * as yup from 'yup'
import { getCurrentLocation } from '@helpers/geo-location'
import { addToDataLayer } from '@helpers/google-tag-manager'
import { emailSignup } from '@services/email'
import { identifyUser } from '@components/integrations/Segment'
import { getLocation } from '@services/location'

export const validationSchema = yup.object({
  terms: yup.boolean().is([true], 'You must accept the terms and conditions before continuing'),
  firstName: yup
    .string('Enter your first name')
    .min(2, 'First name should be of minimum 2 characters length')
    .required('First name is required'),
  lastName: yup.string('Enter your last name').required('Lastname is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/[A-Z]/, 'Password should have at least one uppercase letter')
    .matches(/[a-z]/, 'Password should have at least one lowercae letter')
    .matches(/[0-9]/, 'Password should have at least one number')
    .required('Password is required'),
  zipcode: yup
    .string('Enter your zipcode')
    .length(5, 'Zipcode should contain 5 numbers')
    .matches(/^\d+$/, 'Zipcode should have only numbers')
    .required('Zipcode is required'),
})

const signUpEmailList = user => {
  const subsourcecode = 'rtgaccountmodal'
  const {
    contact: { email },
    address: { zip: zipcode },
  } = user

  getLocation(zipcode).then(loc => {
    const location = getCurrentLocation()
    const storenumber =
      loc && loc.response && loc.response.distribution_index
        ? loc.response.distribution_index
        : location.distribution_index

    // Segment Identify call
    identifyUser({
      email,
      zipcode,
      subsourcecode,
    })
    emailSignup({
      email,
      sourcecode: 'ONLINE',
      sourcekey: 'ONLINE',
      subsourcecode,
      sourcedate: new Date().toISOString(),
      userid: 'rtg-web-user',
      zipcode,
      storenumber,
      signup: 'true',
    })
  })
}

export const useCreateAccountForm = () => {
  const { dispatch, actions } = useModalsContext()
  const { enqueue } = useNotifications()
  const { showPassword, onVisibilityToggle, handleMouseDownPassword } = usePasswordVisibility()

  const onSuccess = useCallback(
    newUser => {
      dispatch({ type: actions.close })
      addToDataLayer('sign_up', 'accounts', 'click', 'uid', newUser._id)
      setTimeout(() => {
        dispatch({ type: actions.reset })
        enqueue({ variant: 'filled', body: 'Your account has been successfully created!' })
      }, 500)
      if (newUser.emailList) signUpEmailList(newUser)
    },
    [dispatch, actions, enqueue],
  )

  const goBackToLogin = useCallback(() => dispatch({ type: actions.login }), [dispatch, actions])

  const { error, mutate, isLoading } = useCreateUserMutation({ onSuccess })

  const { errorMessage, clearMessage } = useAuthErrorMessage(error)

  const onSubmit = useCallback(
    values => {
      clearMessage()
      mutate({ values })
    },
    [clearMessage, mutate],
  )

  const formik = useFormik({
    initialValues: {
      terms: false,
      emailList: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      zipcode: getCurrentLocation().zip,
    },
    validationSchema,
    onSubmit,
  })

  return {
    showPassword,
    onVisibilityToggle,
    formik,
    loading: isLoading,
    errorMessage,
    goBackToLogin,
    handleMouseDownPassword,
  }
}
