import React, { createContext, useContext, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutableQueue from '@models/ImmutableQueue'
import Notifications from '@components/Notifications'

/**
 * @typedef {Object} AlertAction
 * @property {string} text
 * @property {(e) => void} onClick
 */

/**
 * @typedef {Object} NotificationPayload
 * @property {boolen} persist
 * @property {string} title
 * @property {string | Element} body
 * @property {AlertAction[]} actions
 * @property {'error' | 'info' | 'success' | 'warning'} severity
 * @property {'filled' | 'outlined' | 'standard'} variant
 */

/**
 * @typedef {Object} NotificationContextValue
 * @property {object[]} notifications
 * @property {(payload: NotificationPayload) => string} enqueue
 * @property {Function} dequeue
 * @property {(id: string) => void} removeById
 */

/** no operation */
const noop = () => {}

export const notificationQueue = new ImmutableQueue()

// queue actions
const enqueue = payload => notificationQueue.enqueue(payload)
const dequeue = () => notificationQueue.dequeue()
const removeById = id => notificationQueue.removeById(id)

/**
 * Notifications Context
 * @type {NotificationContextValue}
 * */
const initialValue = {
  notifications: [],
  enqueue: noop,
  dequeue: noop,
  removeById: noop,
}

const NotificationContext = createContext(initialValue)

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    const unsubscribe = notificationQueue.subscribe(queue => setNotifications(queue.list))
    return () => unsubscribe()
  }, [])

  /**
   * Notifications Context
   * @type {NotificationContextValue}
   * */
  const context = {
    notifications,
    enqueue,
    dequeue,
    removeById,
  }

  return (
    <NotificationContext.Provider value={context}>
      <Notifications />
      {children}
    </NotificationContext.Provider>
  )
}

NotificationsProvider.propTypes = {
  children: PropTypes.node,
}

/**
 * @returns {NotificationContextValue}
 */
export const useNotifications = () => useContext(NotificationContext)
