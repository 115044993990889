// TODO: Migrate to Poppins
const typography = {
  fontFamily: [
    '"Open Sans"',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontSize: 36,
    fontWeight: 275,
  },
  h2: {
    fontSize: 20,
    fontWeight: 500,
  },
  h3: {
    fontSize: 18,
    fontWeight: 400,
  },
  button: {
    fontSize: 16,
    fontWeight: 500,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
  },
  body2: {
    fontSize: 16,
    fontWeight: 600,
  },
  overline: {
    fontSize: 12,
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: 25,
    fontWeight: 400,
  },
  subtitle3: {
    fontSize: 25,
    fontWeight: 600,
  },
  caption: {
    fontSize: 14,
    fontWeight: 600,
  },
}

export default typography
