// dependencies
import React, { useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby'
import { Link as MuiLink, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
// helpers
import { savePageScroll } from '@helpers/product'
import { useLocation } from '@gatsbyjs/reach-router'
import { canClientSideRoute } from '@helpers/link'

const useStyles = makeStyles(() => ({
  link: {
    position: 'relative',
    '& svg.link-arrow-next': {
      position: 'absolute',
      fontSize: '1.5em',
      top: '50%',
      transform: 'translateY(-52%)',
    },
  },
}))

const noop = () => {}
const isEmptyObject = obj => obj && Object.keys(obj).length === 0

/**
 * Retunrs GTM props and dataLayer from trackingData object
 * @param {object} trackingData
 */
const getAnalytics = trackingData => {
  if (trackingData && typeof trackingData === 'object') {
    const { category = null, action = null, label = null, value = null, ...data } = trackingData
    return {
      GTM: {
        'gtm-category': category,
        'gtm-action': action,
        'gtm-label': label,
        'gtm-value': value,
      },
      DataLayer: isEmptyObject(data) ? null : data,
    }
  }
  return { GTM: {}, DataLayer: null }
}

const formatProps = (props = {}, data = {}, trackingData) => {
  const { AlternateDescription, Title, Target, Color } = data
  const { onClick = noop, onKey = noop, style = {}, ...linkProps } = props
  const analytics = getAnalytics(trackingData)
  return {
    title: AlternateDescription || Title || null,
    target: Target || null,
    onClick: e => {
      if (typeof window !== 'undefined') {
        savePageScroll()
        if (analytics.DataLayer) {
          window.dataLayer.push(analytics.DataLayer)
        }
      }
      onClick(e)
    },
    onKeyDownCapture: onKey,
    style: Color?.ColorHex
      ? {
          color: Color.ColorHex,
          ...style,
        }
      : style,
    ...analytics.GTM,
    ...linkProps,
    // props can overwrite link data from strapi
  }
}

const StrapiLink = ({
  data,
  children = null,
  arrowNext = null,
  trackingData = null,
  className = '',
  ...otherProps
}) => {
  const linkData = data?.Link || data
  const linkProps = useMemo(() => formatProps(otherProps, linkData, trackingData), [otherProps, linkData, trackingData])
  const classes = useStyles()
  const location = useLocation()
  if (linkData?.ExternalUrl) {
    return (
      <MuiLink
        href={linkData?.ExternalUrl}
        className={classNames(classes.link, className)}
        {...linkProps}
        data-testid={linkData?.id}
      >
        {children || linkData?.DisplayText || linkData?.Title}
        {arrowNext && <NavigateNextIcon className="link-arrow-next" />}
      </MuiLink>
    )
  }
  const internalUrl = (linkData?.InternalUrl || '/').startsWith('/')
    ? linkData?.InternalUrl
    : `/${linkData?.InternalUrl}`
  // Since we only run the static query if we are not doing a static build we should still use client side routing if allPages is length 0
  if (canClientSideRoute(location.pathname, internalUrl)) {
    return (
      <MuiLink
        to={internalUrl}
        component={GatsbyLink}
        className={classNames(classes.link, className)}
        {...linkProps}
        data-testid={linkData?.id}
      >
        {children || linkData?.DisplayText || linkData?.Title}
        {arrowNext && <NavigateNextIcon className="link-arrow-next" />}
      </MuiLink>
    )
  }
  return (
    <MuiLink
      href={internalUrl}
      className={classNames(classes.link, className)}
      {...linkProps}
      data-testid={linkData?.id}
    >
      {children || linkData?.DisplayText || linkData?.Title}
      {arrowNext && <NavigateNextIcon className="link-arrow-next" />}
    </MuiLink>
  )
}

StrapiLink.propTypes = {
  data: PropTypes.object.isRequired,
  trackingData: PropTypes.shape({
    event: PropTypes.string,
    ecommerce: PropTypes.object,
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
  }),
  children: PropTypes.node,
  arrowNext: PropTypes.bool,
  className: PropTypes.string,
}

export default StrapiLink

// ? https://github.com/reactjs/reactjs.org/issues/2120
export const ButtonLink = forwardRef(props => <StrapiLink {...props} />)
ButtonLink.displayName = 'ButtonLink'

/**
 * Helper
 * @param {{ Name?: string; Title?: string; Heading?: string; }} data Strapi data
 * @param {object} link Strapi Link data
 * @param {object} trackingData Additional tracking data
 * @returns StrapiLink props
 */
export const getLinkProps = (data, link, trackingData = {}) => ({
  component: ButtonLink,
  data: link,
  trackingData: {
    category: 'promotion',
    action: 'click',
    label: data?.Name || data?.Heading || data?.Title,
    ...trackingData,
  },
})

export const StrapiLinkFragment = graphql`
  fragment StrapiLinkFragment on StrapiLink {
    id
    Title
    DisplayText
    InternalUrl
    ExternalUrl
    Color {
      ColorHex
    }
    Target
    AlternateDescription
  }
`
