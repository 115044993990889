import _RequiredFieldsMessage from './RequiredFieldsMesage'
import _PasswordRequirements from './PasswordRequirements'
import _BackButton from './BackButton'
import _Subtitle from './Subtitle'
import _PhoneInput from './PhoneInput'

export * from './styled'
export const RequiredFieldsMessage = _RequiredFieldsMessage
export const PasswordRequirements = _PasswordRequirements
export const BackButton = _BackButton
export const Subtitle = _Subtitle
export const PhoneInput = _PhoneInput
