import * as React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'

const ProductPrice = ({
  price,
  default_price,
  variant = 'body2',
  sale = false,
  strikeThrough = false,
  strikeThroughPrice = 0,
}) => {
  const color = sale ? 'error' : 'inherit'
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {strikeThrough && (
        <Typography
          variant={variant}
          sx={{
            textDecoration: 'line-through',
          }}
        >
          ${strikeThroughPrice}
        </Typography>
      )}
      <Typography color={color} variant={variant} noWrap>
        ${price}
      </Typography>
    </Stack>
  )
}

ProductPrice.propTypes = {
  default_price: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  strikeThrough: PropTypes.bool,
  strikeThroughPrice: PropTypes.number,
  sale: PropTypes.bool,
  variant: PropTypes.string,
}

export default ProductPrice
