import { useEffect, useCallback, useState } from 'react'
import { useFormik } from 'formik'
import { navigate } from 'gatsby'
import { useForgotPassword, useAuthErrorMessage, resetPasswordByToken } from '@hooks/auth'
import usePasswordVisibility from '@hooks/usePasswordVisibility'
import { useModalsContext } from '@context/modalContext'
import { getURLParam } from '@helpers/general'
import * as yup from 'yup'
import { AuthErrorCodes } from '../../../lib/auth/firebase'

export const forgotPasswordValidationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
})

const resetPasswordValidationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/[A-Z]/, 'Password should have at least one uppercase letter')
    .matches(/[a-z]/, 'Password should have at least one lowercae letter')
    // .matches(
    //   eslint-disable-next-line no-useless-escape
    //   /[~!@#\$%\^&\*\(\)_\+\.,/;:<=>\?]/,
    //   'Password should have at least on special charapter: ~!@#$%^&*()_+.,/;:<=>?',
    // )
    .matches(/[0-9]/, 'Password should have at least one number')
    .required('Password is required'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const useForgotPasswordForm = () => {
  const { dispatch, actions } = useModalsContext()
  const [submitted, setSubmitted] = useState(false)
  const [sendPasswordResetEmail, loading, error] = useForgotPassword()
  const { errorMessage, clearMessage } = useAuthErrorMessage(error)

  const onSubmit = useCallback(
    values => {
      clearMessage()
      sendPasswordResetEmail(values.email).then(() => setSubmitted(true))
    },
    [clearMessage, sendPasswordResetEmail],
  )

  const goBackToLogin = useCallback(() => dispatch({ type: actions.login }), [dispatch, actions])

  useEffect(() => {
    // if the email was not found, in this case don't show any error message to the user
    if ((submitted && !error) || (submitted && error && error.code === AuthErrorCodes.USER_DELETED)) {
      dispatch({ type: actions.emailSent })
    }
  }, [error, dispatch, actions, submitted])

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit,
  })

  return { formik, errorMessage, loading, goBackToLogin }
}

export const useResetPasswordForm = () => {
  const { dispatch, actions } = useModalsContext()
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [firebaseErrorCode, setFirebaseErrorCode] = useState()

  const { errorMessage, clearMessage } = useAuthErrorMessage(firebaseErrorCode)
  const { showPassword, onVisibilityToggle, showConfirmation } = usePasswordVisibility()

  const onSubmit = useCallback(
    values => {
      setLoading(true)
      clearMessage()
      setFirebaseErrorCode()
      const token = getURLParam('oobCode')
      resetPasswordByToken(token, values.password)
        .then(() => setSubmitted(true))
        .catch(error => setFirebaseErrorCode(error))
        .finally(() => setLoading(false))
    },
    [clearMessage],
  )

  useEffect(() => {
    if (submitted && !errorMessage) {
      // REDIRECT TO LOGIN
      navigate('/')
      dispatch({ type: actions.login })
    }
  }, [dispatch, errorMessage, actions, submitted])

  const formik = useFormik({
    initialValues: { password: '', passwordConfirmation: '' },
    validationSchema: resetPasswordValidationSchema,
    onSubmit,
  })

  return { formik, errorMessage, loading, onVisibilityToggle, showPassword, showConfirmation }
}
