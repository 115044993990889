import { styled, Grid, Box, Button } from '@mui/material'

export const FormGrid = styled(Grid)(() => ({
  margin: 0,
  '& > *': {
    padding: '10px',
  },
  '& input[type="text"], & input[type="password"], & input[type="email"]': {
    margin: 0,
    border: 'none !important',
    padding: '10.5px 14px !important',
    boxSizing: 'content-box',
  },
  '& button': {
    minWidth: 'unset !important',
  },
  '& fieldset legend span': {
    marginRight: '8px',
  },
  '& #state': {
    height: '40px',
    boxSizing: 'border-box',
    border: 'none',
    padding: '10.5px 14px',
  },
}))

export const TitleWrapper = styled(Box)(({ theme }) => ({
  borderBottom: `solid 1px ${theme.palette.primary.main}`,
  marginBottom: '1.4rem',
}))

export const InputGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const ButtonGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}))

export const SubmitButton = styled(Button)(({ theme }) => ({
  maxWidth: '12.6rem',

  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}))
