const { basename } = require('path')
const qs = require('querystring')
const chunk = require('lodash/chunk')
const flatten = require('lodash/flatten')
const _get = require('lodash/get')

/**
 * helper
 * @param {object[]} content
 * @returns {boolean}
 */
const hasSearchQuery = content =>
  content && Array.isArray(content) ? content.some(({ __typename }) => __typename === 'PageSearchQuery') : false

/**
 * Returns true if the given value is not an empty Array,
 * meaning it's an array with items inside, otherwise returns false.
 * @param {any} data
 * @returns {boolean}
 */
const isNotEmptyArray = data => Array.isArray(data) && data.length > 0

/**
 * Selects content from a region.
 * @param {'SE' | 'FL' |'TX' | 'OOM'} region
 * @param {object} strapiPage
 * @returns {object[]}
 */
const selectRegionBasedContent = (region = '', strapiPage = {}) => {
  const defaultContent = strapiPage.DefaultContent || null
  const regionBasedContent = region && strapiPage[`Content_${region}`]
  const isNotEmpty = regionBasedContent && Array.isArray(regionBasedContent) && regionBasedContent.length > 0
  return isNotEmpty ? regionBasedContent : defaultContent
}

/**
 * Selects navigation links from a region.
 * @param {'SE' | 'FL' |'TX' | 'OOM'} region
 * @param {object} navigationLinks
 * @returns {object[]}
 */
const selectRegionBasedNavigation = (region = '', header = {}) => {
  const linksByRegion = {
    FL: 'FloridaLinks',
    TX: 'TexasLinks',
    OOM: 'OOMLinks',
    SE: 'SouthEastLinks',
    PR: 'DefaultLinks',
  }
  const defaultLinks = header?.NavigationLinks || null
  const regionBasedContent = region && header[linksByRegion[region] || '']
  const isNotEmpty = regionBasedContent && Array.isArray(regionBasedContent) && regionBasedContent.length > 0
  return isNotEmpty ? regionBasedContent : defaultLinks
}

/**
 * helper
 * @param {string} route
 * @param {string[]} excludedPages
 * @returns {boolean}
 */
const isExcluded = (route, excludedPages) => excludedPages.some(page => route === page || route.endsWith(page))

/**
 * helper
 * @param {object} data
 * @returns {object[]}
 */
const getPages = data => {
  const pages = data && data.allStrapiPage && data.allStrapiPage.edges
  return Array.isArray(pages) && pages.length > 0 ? pages : []
}

/**
 * Page async parallel processing
 * @param {object[]} data
 * @param {number} size
 * @param {function} mapCallback
 */
const inParallel = async (data, size, mapCallback) => {
  const promises = chunk(data, size).map(
    dataChunk =>
      new Promise(resolve => {
        const value = dataChunk.map(mapCallback)
        resolve(value)
      }),
  )
  const results = await Promise.all(promises)
  return flatten(results, true)
}

/**
 * Maps strapi spacing to its corresponding material-ui spacing value
 * @param {'none' | 'small' | 'medium' | 'large'} spacing
 * @returns {number} material-ui spacing prop
 */
const mapSpacing = spacing => {
  const values = {
    none: 0,
    small: 2,
    medium: 4,
    large: 6,
  }
  return values[spacing] || 0
}

/**
 * Awaits a given timeout
 * @param {number} wait
 * @returns {Promise<void>}
 */
const sleep = wait =>
  new Promise(resolve => {
    setTimeout(resolve, wait)
  })

/**
 * Non-flicker data loader (function wrapper), prevents UI flashes when data loads too fast
 * @param {(any) => Promise<any>} callback should always return a promise
 * @param {number} minimunWait 1800 milliseconds by default
 * @param {boolean} enabled if false anti-flickering will be not applied - defaults true
 * @returns {(any) => Promise<any>} callback enhanced with anti-flickering
 */
const withoutFlickering = (callback, minimunWait = 1200, enabled = true) => async (...args) => {
  const time = Date.now()
  const result = await callback(...args)
  if (!enabled) {
    return result
  }
  if (time + minimunWait - Date.now() <= 0) {
    return result
  }
  await sleep(time + minimunWait - Date.now())
  return result
}

/**
 * Request banner from gatsby static data
 * @param {function} graphql
 * @param {number} bannerID
 * @param {string} bannerFragment
 * @returns {object | null}
 */
const requestBanner = async (graphql, bannerID, bannerFragment) => {
  if (!bannerID) {
    return null
  }

  const response = await graphql(`
    {
      strapiBanner(id: { eq: "banner:${bannerID}" }) {
        ${bannerFragment}
      }
    }
  `)

  return _get(response, 'data.strapiBanner', null)
}

/**
 * Maps ColumnSize banners attribute to M-UI grid breakpoint.
 * https://v4.mui.com/components/grid/#auto-layout
 * @param {number} ColumnSize
 * @returns {number | true} When ColumnSize is 0 or NaN will return true which enables auto-layout,
 * otherwise returns a number between 1-12
 */
const toGridBreakpoint = (ColumnSize = 0) => {
  const size = Number(ColumnSize)
  if (!Number.isNaN(size) && size > 0 && size < 13) {
    return size
  }
  return true
}

/**
 * @param {string} typename
 * @returns {string} type attribute name
 */
const getAttributeName = typename => {
  if (typename === 'PageHtml') {
    return 'HTML'
  }
  return typename.replace('Page', '')
}

/**
 * Generates unique keys based on strapi content id
 * @param {object} contentData
 * @param {string} prefix
 * @returns
 */
const getTypeKey = (contentData, prefix = '') => {
  const name = getAttributeName(contentData.__typename)
  const typeId = contentData && contentData[name] && contentData[name].id
  return `${prefix}${contentData.__typename}-${typeId}`.toLowerCase()
}

module.exports = {
  hasSearchQuery,
  selectRegionBasedContent,
  isExcluded,
  inParallel,
  getPages,
  mapSpacing,
  withoutFlickering,
  sleep,
  requestBanner,
  selectRegionBasedNavigation,
  isNotEmptyArray,
  toGridBreakpoint,
  getTypeKey,
}
