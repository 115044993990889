import React from 'react'
import { TextField, Grid, Typography, IconButton, InputAdornment, Box, CircularProgress, styled } from '@mui/material'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useResetPasswordForm } from './hooks'
import PasswordRequirements from '../Common/PasswordRequirements'

import { RequiredFieldsMessage, FormGrid, SubmitButton, InputGrid } from '../Common'

export const MODAL_RESET_PWD_ID = 'MODAL/RESET_PASSWORD'

const handleMouseDownPassword = event => {
  event.preventDefault()
}

const StyledFormActions = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const ResetPassword = () => {
  const { formik, errorMessage, loading, onVisibilityToggle, showPassword, showConfirmation } = useResetPasswordForm()

  return (
    <Box>
      <RequiredFieldsMessage />
      <FormGrid spacing={2} component="form" container onSubmit={formik.handleSubmit}>
        <InputGrid item xs={12} sm={12} md={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="password"
            autoComplete="new-password"
            name="password"
            label="New password"
            type={showPassword ? 'text' : 'password'}
            required
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onVisibilityToggle('password')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputGrid>
        <InputGrid item xs={12} sm={12} md={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="passwordConfirmation"
            autoComplete="new-password"
            name="passwordConfirmation"
            label="Confirm New password"
            type={showConfirmation ? 'text' : 'password'}
            required
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
            helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={onVisibilityToggle('passwordConfirmation')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmation ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputGrid>
        {errorMessage && (
          <Grid item xs={12} sm={12} md={12}>
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
        )}
        <StyledFormActions item xs={12} sm={12} md={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <SubmitButton
              disabled={!formik.values.password || !formik.values.passwordConfirmation}
              color="primary"
              variant="contained"
              fullWidth
              size="large"
              type="submit"
            >
              CREATE NEW PASSWORD
            </SubmitButton>
          )}
        </StyledFormActions>
        <Grid item xs={12} sm={12} md={12}>
          <PasswordRequirements success={!(formik.touched.password && formik.errors.password)} />
        </Grid>
      </FormGrid>
    </Box>
  )
}
