import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  list: {
    paddingLeft: theme.spacing(2),
    listStyle: 'initial',
  },
  title: {
    // fontWeight: 600,
  },
}))

const requirements = [
  { text: 'Must be at least 8 characters long', id: 'req-1' },
  { text: 'Must have at least one uppercase letter', id: 'req-2' },
  { text: 'Must have at least one lowercase letter', id: 'req-3' },
  { text: 'Must have at least one number', id: 'req-4' },
  // { text: 'One special character ~!@#$%^&*()_+.,/;:<=>?', id: 'req-5' },
]

const PasswordRequirements = () => {
  const classes = useStyles()
  return (
    <Box>
      <Box className={classes.list} component="ul">
        {requirements.map(({ id, text }) => (
          <Typography key={id} variant="body1" component="li">
            {text}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}

export default PasswordRequirements
