/* eslint-disable import/prefer-default-export */
import { request, HttpError } from '@helpers/general'

const service = ({ path = '/favorites', body = null, method = 'GET', token = null }) => {
  if (!token) {
    return Promise.reject(new HttpError('Favorites Service Error: No token was provided'))
  }
  const baseUrl = process.env.GATSBY_USER_FAVORITES_SERVICE
  return request(path, body, method, token, 'Favorites Service Error', baseUrl, 'X-Token')
}

export const api = {
  get: token => service({ token }),
  post: ({ token, body = null }) => service({ body, method: 'POST', token }),
  put: ({ token, body = null }) => service({ body, method: 'PUT', token }),
  delete: ({ token, body = null }) => service({ body, method: 'DELETE', token }),
}

export const favoritesQuery = ({ queryKey }) => {
  const [key, token] = queryKey
  return api.get(token)
}

export const updateFavorites = token => async ({ values = {} }) => {
  let append
  if (!token) {
    throw new HttpError('updateFavorites Service Error: No token was provided')
  }
  const { products, remove } = values
  // if remove is null then we are adding a favorite
  if (!remove) {
    append = true
  }
  const result = await api.put({ token, body: { products, append, remove } })
  return result
}
