import React from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Paper,
  Container,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { MODAL } from '../../..'

export default function QuickViewModal({ addToCart, isOpen, closeModal, price_zone, isMobile, product_json }) {
  const { title, pricing, route, primary_image, hasVariations } = product_json
  const list_price = pricing[`${price_zone}_list_price`]
  const sale_price = pricing[`${price_zone}_sale_price`]

  const CLOSE_MODAL_BUTTON = () => (
    <Box position="absolute" top={4} right={4} zIndex={10}>
      <Tooltip describeChild title="Close the quick shop modal.">
        {/* [GTM] - Track when user closes modal using the close button */}
        <IconButton color="primary" onClick={() => closeModal('closeButton')}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </Box>
  )
  // TODO: Add border variants to design tokens
  const PRODUCT_VARIANTS = () => (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <Typography fontWeight={400} variant="body1">
        More Options Available
      </Typography>
      <Button
        href={`/furniture/product${route}`}
        size="small"
        variant="text"
        color="primary"
        disableRipple
        disableElevation
        disableTouchRipple
        sx={{
          textTransform: 'capitalize',
          fontSize: '14px',
          fontStyle: 'italic',
          color: 'secondary.dark',
          textDecoration: 'underline',
        }}
      >
        See Full Details
      </Button>
    </Stack>
  )

  return (
    <MODAL
      isOpen={isOpen}
      onClose={(event, reason) => {
        closeModal(reason)
      }}
      aria-labelledby="quickView-modal-title"
      aria-describedby="quickView-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Container
          disableGutters
          fixed
          maxWidth="sm"
          sx={{
            minWidth: isMobile ? '100%' : 'initial',
            position: 'absolute',
          }}
        >
          <Card
            elevation={2}
            sx={{
              borderRadius: '2px',
              position: 'relative',
              height: isMobile ? '100vh' : 'auto',
            }}
          >
            {isMobile && (
              <Typography align="center" variant="body1" p="12px" id="quickView-modal-title">
                QUICK VIEW
              </Typography>
            )}
            <CLOSE_MODAL_BUTTON />
            <Stack justifyContent="space-between" height={isMobile ? '100%' : 'auto'}>
              <CardMedia
                key={route}
                component="img"
                height={365}
                image={primary_image}
                alt={title}
                sx={{
                  p: 6,
                  maxWidth: isMobile ? 365 : '100%',
                  m: '0 auto',
                  objectFit: 'contain',
                  aspectRatio: isMobile ? '1/1' : '2/1',
                }}
              />
              <Stack sx={{ flex: 2 }}>
                <CardContent
                  sx={{ p: 2, bgcolor: 'secondary.light', flexGrow: 2, flexBasis: '50%', overflow: 'scroll' }}
                >
                  <>
                    {/* TITLE */}
                    <Typography variant="subtitle2" lineHeight="34px" id="quickView-modal-description">
                      {title}
                    </Typography>
                    {/* PRICE */}
                    <Typography variant="subtitle3" fontWeight={600} fontSize={25} lineHeight="34px">
                      ${list_price > sale_price ? sale_price : list_price}
                    </Typography>
                    {/* AVAILABILITY */}
                    {/* VARIANTS */}
                    {hasVariations && <PRODUCT_VARIANTS />}
                  </>
                </CardContent>
                <Paper
                  elevation={3}
                  sx={{
                    width: '100%',
                    flex: 'auto',
                  }}
                >
                  <CardActions
                    sx={{
                      py: isMobile ? 1 : 3,
                      px: isMobile ? 1 : 4,
                    }}
                  >
                    <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 1 : 3} width="100%">
                      {/* VIEW PDP */}
                      <Button
                        href={`/furniture/product${route}`}
                        variant="outlined"
                        color="primary"
                        size={isMobile ? 'medium' : 'large'}
                        fullWidth
                        disableElevation
                        onClick={() => {
                          // [GTM] - Tracks when user navigates to PDP from modal
                          closeModal('Navigated to PDP')
                        }}
                        sx={{
                          borderWidth: 2,
                        }}
                      >
                        View Full Details
                      </Button>
                      {/* ADD TO CART */}
                      <Button
                        variant="contained"
                        color="primary"
                        size={isMobile ? 'medium' : 'large'}
                        fullWidth
                        disableElevation
                        onClick={() => {
                          // [GTM] - Tracks when user adds product to cart from modal
                          closeModal('Product added to cart')
                          return addToCart(product_json)
                        }}
                      >
                        Add To Cart
                      </Button>
                    </Stack>
                  </CardActions>
                </Paper>
              </Stack>
            </Stack>
          </Card>
        </Container>
      </Box>
    </MODAL>
  )
}

QuickViewModal.propTypes = {
  product_json: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    route: PropTypes.string,
    pricing: PropTypes.array,
    quantity: PropTypes.number,
    grid_image: PropTypes.string,
    primary_image: PropTypes.string,
    hasVariations: PropTypes.bool,
    sku: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  price_zone: PropTypes.string,
  addToCart: PropTypes.func,
  isMobile: PropTypes.bool,
}
