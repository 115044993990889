import React from 'react'

import { Box, styled } from '@mui/material'
import { motion, AnimatePresence } from 'framer-motion'

import { useNotifications } from '@context/notifications'
import { TOAST_NOTIFICATION } from '../@rtg2022'

const Wrapper = styled(Box)`
  position: fixed;
  top: ${({ theme }) => theme.spacing(14)};
  right: 0;
  z-index: 4000;
`

const List = styled('ul')`
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: flex-end;
  align-items: flex-end;
`

const ListItem = styled(motion.li)`
  margin: ${({ theme }) => theme.spacing(1)};
  position: relative;
  display: flex;
`

const Notifications = () => {
  const { notifications, removeById } = useNotifications()
  const createOnCloseHandler = queue_id => () => removeById(queue_id)

  return (
    <Wrapper>
      <List>
        <AnimatePresence initial={false}>
          {notifications.map(({ queue_id, payload }) => {
            // if the notification won't persit, we dont need a close btn
            const props = payload?.persist ? { onClose: createOnCloseHandler(queue_id), ...payload } : payload
            return (
              <ListItem
                key={queue_id}
                layout
                initial={{ opacity: 0, y: 50, scale: 0.3 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
              >
                <TOAST_NOTIFICATION {...props} />
              </ListItem>
            )
          })}
        </AnimatePresence>
      </List>
    </Wrapper>
  )
}

export default Notifications
