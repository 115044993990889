import React from 'react'
import { colors } from '@constants/styles'
import { objectOf, any, func, number, bool, shape } from 'prop-types'
import styled from 'styled-components'
import { Highlight } from 'react-instantsearch-dom'

const Suggestion = styled.li`
  &&& {
    color: #7e7e7e;
    font-size: 1.2rem;
    margin: 0;
    max-width: 600px;
    min-width: 140px;
    text-transform: lowercase;
    text-align: left;

    &:hover {
      color: ${colors.textHighlight};
      cursor: pointer;
    }

    .ais-Highlight-highlighted {
      color: ${colors.textHighlight};
      font-weight: bold;
    }
  }
`

const ConnectedSuggestion = ({
  suggestion = {},
  onFormSubmit,
  index,
  selected = false,
  suggestionsKeyEvent,
  suggestionRef,
  mobileHitsCount,
}) => (
  <Suggestion
    onClick={async event => {
      suggestionsKeyEvent(event)
      return onFormSubmit(event, true, suggestion.query, index)
    }}
    onKeyDown={event => suggestionsKeyEvent(event)}
    role="option"
    data-index={`searchSuggest${index}`}
    id={mobileHitsCount ? `searchSuggest${index}-mobile` : `searchSuggest${index}`}
    aria-selected={selected}
    tabIndex={0}
    className={selected && 'selected'}
    ref={index === 0 ? suggestionRef : null}
  >
    <Highlight hit={suggestion} attribute="query" />
  </Suggestion>
)
ConnectedSuggestion.propTypes = {
  suggestion: objectOf(any),
  onFormSubmit: func,
  index: number,
  selected: bool,
  suggestionsKeyEvent: func,
  suggestionRef: shape({ current: any }),
  mobileHitsCount: bool,
}

export default ConnectedSuggestion
