import React from 'react'

import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import DevicesIcon from '@mui/icons-material/Devices'

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .alert-action {
    text-decoration: underline;
    font-weight: 600;
  }
`

const StyledAlert = styled(MuiAlert)(({ theme, snackbar, filled }) => ({
  boxShadow: theme.shadows[4],
  maxWidth: 500,
  '& .alert-body': {
    color: snackbar || filled ? theme.palette.common.white : null,
  },
}))

const Alert = ({ title = null, body = null, actions = null, ...otherProps }) => {
  const isSnackbar = otherProps?.variant === 'snackbar'

  const action = actions ? (
    <Actions>
      {actions.map(({ text, onClick }) => {
        const key = `actions-${text.toLowerCase()}`
        return (
          <Button className="alert-action" key={key} color="inherit" size="small" onClick={onClick}>
            {text}
          </Button>
        )
      })}
    </Actions>
  ) : null

  const props = isSnackbar ? { ...otherProps, icon: <DevicesIcon /> } : { ...otherProps, action }
  return (
    <StyledAlert
      filled={otherProps?.variant === 'filled' ? true : undefined}
      snackbar={isSnackbar ? true : undefined}
      {...props}
    >
      {title && <AlertTitle sx={!body && { marginBottom: 0 }}>{title}</AlertTitle>}
      {body && typeof body === 'string' ? (
        <Typography className="alert-body" sx={{ fontSize: '0.875rem' }}>
          {body}
        </Typography>
      ) : (
        body
      )}
      {isSnackbar && action}
    </StyledAlert>
  )
}

Alert.propTypes = {
  title: PropTypes.string,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard', 'snackbar']),
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
  ),
}

export default Alert
