import * as React from 'react'
import PropTypes from 'prop-types'
import { Backdrop, Modal, Fade } from '@mui/material'

export default function MODAL({ isOpen, onClose, children }) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>{children}</Fade>
    </Modal>
  )
}

MODAL.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
