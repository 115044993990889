import React, { useRef } from 'react'

import PropTypes from 'prop-types'
import useStaticNavigationQuery from '@hooks/useStaticNavigationQuery'
import { styled } from '@mui/material'
import NavItem from './NavItem'

const Nav = styled('nav')`
  display: flex;
  width: 100%;
  padding: 0 4px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  justify-content: center;
  ${p => p.theme.breakpoints.down('md')} {
    display: none;
  }
`

const List = styled('ul')`
  display: flex;
  justify-content: center;
`

const Navigation = ({ onSetBackdrop }) => {
  const navigation = useStaticNavigationQuery()
  const menuRef = useRef(null)
  return (
    <Nav
      className="desktop-only"
      id="primary-nav-desktop"
      role="navigation"
      aria-label="Primary Navigation"
      ref={menuRef}
    >
      <List>
        {navigation.map(({ HeaderNavLinks }) => {
          const contentID = HeaderNavLinks?.id
          return (
            <NavItem
              key={contentID}
              data={HeaderNavLinks}
              contentID={contentID}
              menuRef={menuRef}
              onSetBackdrop={onSetBackdrop}
            />
          )
        })}
      </List>
    </Nav>
  )
}

Navigation.propTypes = {
  onSetBackdrop: PropTypes.func.isRequired,
}

export default Navigation
