import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import { connect } from 'react-redux'
import { geoLocation, getCurrentLocation } from '@helpers/geo-location'
import { addToDataLayer } from '@helpers/google-tag-manager'
import { setShippingAddress } from '@redux/modules/location'
import { saveLocalStorage } from '@helpers/storage'
import LocationFields from '../shared/location-fields'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  & > div {
    margin: 0 auto;
    padding: 10px 0;
    width: 90%;
  }
`
const Header = styled('div')`
  border-bottom: 1px solid #dedede;
`
const Body = styled('div')`
  border-bottom: 0;
  padding-top: 5px;
  text-align: center;
`
const DeliverToContainer = styled('div')`
  font-weight: 500;
  text-align: left;
`
const Description = styled('p')`
  font-size: 0.875rem;
  text-align: left;
  margin-bottom: 10px;
`

const ShippingChangeZip = ({ shipping_address, onSetShippingAddress }) => {
  const [addressInput, setAddressInput] = useState('')
  const [error, setError] = useState(false)
  const addrArr = shipping_address ? shipping_address.split(',') : []

  const handleSubmit = async event => {
    event.preventDefault()
    const isPRZip =
      (Number(addressInput) >= 600 && Number(addressInput) <= 799) ||
      (Number(addressInput) >= 900 && Number(addressInput) <= 999)

    /* explicitly define the allowed URLs */
    const allowedUSDevHosts = ['rtg-dev.com', 'www.rtg-dev.com']
    const allowedUSProdHosts = ['roomstogo.com', 'www.roomstogo.com']

    /* grab the domain from the URL */
    let windowHost = ''
    if (typeof window !== 'undefined') {
      windowHost = window.location.hostname
    }

    /* if the user is on .com dev or prod and enters a Puerto Rico zipcode, redirect to the PR website */
    if (allowedUSDevHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.rtg-dev.pr?zip=${Number(addressInput)}`
    } else if (allowedUSProdHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.roomstogo.pr?zip=${Number(addressInput)}`
    } else {
      const currentZip = getCurrentLocation().zip
      const err = await geoLocation(addressInput)
      if (err) {
        setError(true)
        document.getElementById('locationHeader').focus()
      } else {
        saveLocalStorage('changeLocReturn', 'changeLocHeaderBtn')
      }
      addToDataLayer('click', 'header', 'shipping change', `${currentZip},${getCurrentLocation().zip}`)
    }
  }

  const onChange = event => {
    setError(false)
    setAddressInput(event.target.value)
  }

  useEffect(() => {
    if (addrArr[0] === '') {
      onSetShippingAddress(addrArr[1])
    }
  }, [addrArr, onSetShippingAddress])

  return (
    <Container>
      <Header>
        <DeliverToContainer>
          <strong>
            Shop &amp; Deliver To: <span>{shipping_address}</span>
          </strong>
        </DeliverToContainer>
      </Header>
      <Body>
        <Description>View product availability in your area by entering delivery location.</Description>
        <div className="form">
          <form onSubmit={e => handleSubmit(e)}>
            <LocationFields error={error} addressInput={addressInput} onChange={onChange} noAutoComplete id="Header" />
          </form>
        </div>
      </Body>
    </Container>
  )
}

ShippingChangeZip.propTypes = {
  onSetShippingAddress: PropTypes.func,
  shipping_address: PropTypes.string,
}

const mapStateToProps = state => ({ ...state.location })

const mapDispatchToProps = dispatch => ({
  onSetShippingAddress: shipping_address => dispatch(setShippingAddress(shipping_address)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShippingChangeZip)
