import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types'
import { styled, keyframes, createTheme, ThemeProvider } from '@mui/material/styles'
import { Box } from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

import FavoriteIcon from '@mui/icons-material/Favorite'

// Animations
const heartPulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`

const heartUnlike = keyframes`
  50% { transform: scale(.75) ; }
`

const heartFloatMain1 = keyframes`
  0% {
    opacity: 0;
    transform: translate(0) rotate(0);
  }
  50% {
    opacity: 1;
    transform: translate(0, -25px) rotate(-20deg)
  }
`

const heartFloatMain2 = keyframes`
  0% {
    opacity: 0;
    transform: translate(0) rotate(0) scale(0);
  }
  50% {
    opacity: .9;
    transform: translate(-10px, -38px) rotate(25deg) scale(1);
  }
`

const heartFloatSub1 = keyframes`
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }
  50% {
    visibility: visible;
    transform: translate(13px, -13px) rotate(30deg);
  }
`

const heartFloatSub2 = keyframes`
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }
  50% {
    visibility: visible;
    transform: translate(18px, -10px) rotate(55deg);
  }
`

const heartFloatSub3 = keyframes`
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }
  50% {
    visibility: visible;
    transform: translate(-10px, -10px) rotate(-40deg);
  }
  100% {
    transform: translate(-50px, 0);
  }
`

const heartFloatSub4 = keyframes`
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }
  50% {
    visibility: visible;
    transform: translate(2px, -18px) rotate(-25deg);
  }
`

const theme = createTheme()
const bezier = 'cubic-bezier(.175, .885, .32, 1.275)'

const HeartButton = styled(Checkbox)`
  line-height: 0;
  background: rgba(255, 255, 255, 0.7);
  transition: all 0.25s ${bezier};
  user-select: none;
  &:hover {
    background: rgba(255, 255, 255, 1);
  }
  .icon {
    position: relative;
    background-size: 100%;
    display: inline-block;
    transition: all 0.25s ${bezier};
    color: ${theme.palette.error.main};
    animation: ${heartUnlike} 0.25s ${bezier} both;
    &.liked {
      animation: ${heartPulse} 0.25s ${bezier} both;
      [class^='heart'] {
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuMTAxIDQuNDE3UzguODk1LjIwNyA1LjExMS4yMDdjLTQuNDY1IDAtMTAuOTY3IDYuODQ2IDUuMDgyIDE3LjU5MkMyNS4yMzcgNy4wMyAxOS42NjUuMjAyIDE1LjUwMS4yMDJjLTQuMTYyIDAtNS40IDQuMjE1LTUuNCA0LjIxNXoiIGZpbGw9IiNGRjZFNkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==')
          no-repeat center;
        background-size: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 14px;
        opacity: 0;
        &::before,
        &::after {
          content: '';
          background: inherit;
          background-size: 100%;
          width: inherit;
          height: inherit;
          display: inherit;
          position: relative;
          top: inherit;
          left: inherit;
          opacity: 0;
        }
      }
      .heartOne {
        animation: ${heartFloatMain1} 1s ${bezier} both;
        &::before,
        &::after {
          width: 12px;
          height: 10px;
          visibility: hidden;
        }
        &::before {
          opacity: 0.6;
          animation: ${heartFloatSub1} 1s 0.25s ${bezier} both;
        }
        &::after {
          animation: ${heartFloatSub2} 1s 0.15s ${bezier} both;
          opacity: 0.75;
        }
      }

      .heartTwo {
        animation: ${heartFloatMain2} 1s 0.1s ${bezier} both;

        &::before,
        &::after {
          width: 10px;
          height: 8px;
          visibility: hidden;
        }
        &::before {
          animation: ${heartFloatSub3} 1s 0.25s ${bezier} both;
          opacity: 0.25;
        }
        &::after {
          animation: ${heartFloatSub4} 1s 0.15s ${bezier} both;
          opacity: 0.4;
        }
      }
    }
  }
`

export default function FavoriteButton({ checked, handleClick }) {
  return (
    <ThemeProvider theme={theme}>
      {/* @ts-ignore */}
      <HeartButton
        onClick={() => handleClick()}
        checked={checked}
        icon={
          <div className="icon">
            <FavoriteBorderIcon />
          </div>
        }
        checkedIcon={
          <div className="icon liked">
            <FavoriteIcon />
            <div className="heartOne" />
            <div className="heartTwo" />
          </div>
        }
      />
    </ThemeProvider>
  )
}
FavoriteButton.propTypes = {
  checked: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
}
